import React, { Component } from "react";
import { SessionSimple } from "./SessionSimple";
import { RuleSimple } from "./RuleSimple";
import { get_progress } from "../../core/utils/session-collection-progress";
import { formatRules } from "../utils/FormatRules";

export class ComplianceBucket extends Component {
  constructor(props){
    super(props);
    this.state = {
      rules: formatRules(props.rules, props.evaluation),
    }
  }


  render() {
    return(
      <div className="overflow-auto bg-gray-200 h-full w-1/3 rounded-xl mt-3">
        <div className="w-full border-zinc-400 border-b-2">
          <label className="font-semibold flex justify-center text-xl text-gray-700 mt-2">{this.props.rules.title}</label>
        </div>
        <div className="w-full border-zinc-400 border-b-2">
          <div className="mb-3 px-3">
            {!Object.entries(this.props.metrics) ? null : Object.entries(this.props.metrics).map(block => <SessionSimple {...block} setGraph={this.props.setGraph} activeGraph={this.props.graph}/>)}
          </div>
        </div>
        {/* ---------------------------- RULES SECTION ---------------------------- */}
        <div className="w-full border-zinc-400 border-b-2">
          <div className="mb-2">
            <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2">RULES</label>
            {!Object.entries(this.state.rules) ? null : Object.entries(this.state.rules).map(block => <RuleSimple {...block} setGraph={this.props.setGraph} activeGraph={this.props.graph}/>)}
          </div>
        </div>
        {/* ---------------------------- SESSION INFO SECTION ---------------------------- */}
        <div className="w-full">
          <div className="mb-2">
            <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2">SESSION INFORMATION</label>
            <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2">Start Date: {this.props.startDate}</label>
            <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2">Sets Required: {this.props.rules.min_threshold}</label>
            <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2">Title: {this.props.rules.title}</label>
            {Object.keys(this.props.evaluation).length == 0 ?
              <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2">Session Status: Compliant</label> :
              <label className="font-semibold flex justify-center text-lg text-red-600 mt-2">Session Status: Not Compliant</label>
            }
            {
              this.props.collectionProgress === 1 || this.props.collectionProgress === 2 ?
              <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2">Session is being under collected</label> : null
            }
            {
              this.props.collectionProgress === 3 || this.props.collectionProgress === 4 ?
              <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2">Session is being over collected</label> : null
            }
          </div>
        </div>
      </div>
    )
  }
}