import Menubar from './modules/core/components/Menubar';
import BalanceFooter from './modules/core/components/Footer';
import Home from './modules/core/pages/Home';
import Signup from './modules/auth/pages/signup';
import Portal from './modules/portal/pages/portal';
import Login from './modules/auth/pages/login';
import Dashboard from './modules/dashboard/pages/dashboard';
import PatientData from './modules/patient-data/pages/patient-data';
import NotFoundPage from './modules/core/pages/NotFoundPage';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import AutoLogout from './modules/auth/components/AutoLogout';
import React from 'react';
import { useEffect, useState } from 'react';
import { registerLicense } from '@syncfusion/ej2-base';
import Logout from './modules/auth/components/logout';
import DataLoader from './modules/core/components/DataLoader';
import Verify2fa from './modules/auth/components/verify_2fa';
import ForgotPassword from './modules/auth/pages/ForgotPassword';
import ResetPassword from './modules/auth/pages/ResetPassword';

/**
 * Top level component
 * @returns React App
 */
function App(props) {
  const [isComponentVisible, setComponentVisibility] = useState(false);
  useEffect(() => {
    document.title = "Patient Management Portal"
  }, []);

    // Function to show the DataLoader
    window.showDataLoader = () => {
      if (props.local){setComponentVisibility(true);}
    };
    window.hideDataLoader = () => {
      if (props.local){setComponentVisibility(false);}
    }

  registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cXGNCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXdcd3VdRWFdVE1/X0I=');

  return (
    <>
      
      <Router>
        <Menubar/>
        {/* <DataLoader/> */}
          <Switch>
            <Route path='/signup' exact component={() => <Signup/>} />
            <Route path='/login' exact component={() => <Login/>}/>
            <Route path='/verify_2fa' exact component ={() => <Verify2fa/>}/>
            <Route path='/forgotPassword' exact component={() => <ForgotPassword/>}/>
            <Route path='/resetPassword' exact component={() => <ResetPassword/>}/>
            <Route path='/' exact component={() => <Home/>} />
            <AutoLogout>
              <Route path='/portal' exact component={() => <Portal/>} />
              <Route path='/portal/rm/:pid' exact component={() => <Portal/>} />
              <Route path='/portal/patient-data/:pid' exact component = {() => <PatientData/>} />
              <Route path='/404' exact component={() => <NotFoundPage/>} />
              <Route path='/dashboard' exact component ={() => <Dashboard/>}/>
              <Route path='/logout' exact component ={() => <Logout/>}/>
            </AutoLogout>
            <Redirect to="/404" />
          </Switch>
      </Router>
      {/* <DataLoader/> */}
      {isComponentVisible && <DataLoader/>}
      <BalanceFooter/>
    </>
  );
}

export default App;
