import React, { Component } from "react";

export class DataLoader extends Component{

  constructor(props) {
    super(props);
    this.state = {
      shouldRender: false,
    };
  }

  componentDidMount() {
    // Check if the component is called from the console
    if (window.devConsoleComponent) {
      this.setState({
        shouldRender: true,
      });
    }
  }

  render(){
    return (
      <>
        <div className="fixed mt-10 z-40 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
          <div className="bg-white h-4/6 overflow-auto overscroll-none px-6 pb-6 rounded">
            HELLO FROM THE CONSOLE
          </div>
        </div>
      </>
    )
  }
}


export default DataLoader;