/**
 * Function which takes a list and returns a new list queried by the search term
 * @param {Object} list the list of all values to be queried against
 * @param {String} searchTerm the search term which is being used to search the list
* @returns {Object} the list of matches in the original list
 */
export function getResultList(list, searchTerm) {
  const lowerCaseSearchTerm = searchTerm.toLowerCase().trim();

  // Split apart the search term in case of spaces
  const searchParts = lowerCaseSearchTerm.split(/\s+/).slice(0, 2);

  return list.filter(user => {
    if (user.firstName && user.lastName) {
      const firstNameMatch = user.firstName.toLowerCase();
      const lastNameMatch = user.lastName.toLowerCase();

      // Check for single-word search or "FirstName LastName"
      if (searchParts.length === 1) {
        return (
          firstNameMatch.includes(searchParts[0]) ||
          lastNameMatch.includes(searchParts[0])
        );
      } else if (searchParts.length === 2) {
        return (
          firstNameMatch.includes(searchParts[0]) &&
          lastNameMatch.includes(searchParts[1])
        );
      }
    }
    return false;
  });
}