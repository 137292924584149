/**
 * Removes a provided value from an array
 * @param {Object} object The state object
 * @returns {Object} The formatted Info Block for the add session request
 */
export function formatInfoBlock(object) {
    let body = {}
    
    body['pid'] = JSON.parse(sessionStorage.getItem("data")).pid
    body['save'] = object.saveTemplate
    return(body);
}

/**
 * Removes a provided value from an array
 * @param {Object} object The state object
 * @returns {Object} The formatted Session Block for the add session request
 */
export function formatSessionBlock(object) {
    let body = {}
    let form_stat_obj = {}
    let gait_stat_obj = {}
    for(let i = 0; i < object.metrics.length; i++) {
        if(object.metrics[i].metric === 'form') {
            form_stat_obj[object.metrics[i].statistic] = {
                compare: object.metrics[i].operator,
                value: object.metrics[i].value
            }
        }
        if(object.metrics[i].metric === 'gait_velocity') {
            gait_stat_obj[object.metrics[i].statistic] = {
                compare: object.metrics[i].operator,
                value: object.metrics[i].value
            }
        }
    }
    let metrics = {}
    if(Object.keys(form_stat_obj).length > 0) {
        metrics['form'] = form_stat_obj
    }
    if(Object.keys(gait_stat_obj).length > 0) {
        metrics['gait_velocity'] = gait_stat_obj
    }

    let rules = {
        title: object.sessionName,
        period: object.period,
        min_threshold: object.minimumThreshold,
        metrics: metrics
    }
    
    body['start_date'] = object.startDate
    body['rules'] = rules
    return(body);
}
