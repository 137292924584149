import React, { Component } from "react";
import { ProgressBarComponent } from '@syncfusion/ej2-react-progressbar'; 

export class SessionCompletionProgressBar extends Component {
  constructor(props){
    super(props);
  }

  getPrimaryColor() {
    if (this.props.colorScheme == 0) {
      // Green
      return('#63A341');
    } else if (this.props.colorScheme == 1) {
      // Yellow (Under Collected)
      return('#E3C12C')

    } else if (this.props.colorScheme == 2) {
      // Red (Under Collected)
      return('#CD4631')
      
    } else if (this.props.colorScheme == 3) {
      // Yellow (Over Collected)
      return('#E3C12C')
      
    } else if (this.props.colorScheme == 4) {
      // Red (Over Collected)
      return('#CD4631')
    }
  }
  getSecondaryColor() {
    if (this.props.colorScheme == 0) {
      // Green
      return('#AFD79A')
    } else if (this.props.colorScheme == 1) {
      // Yellow (Under Collected)
      return('#FFE883')

    } else if (this.props.colorScheme == 2) {
      // Red (Under Collected)
      return('#DF8375')
      
    } else if (this.props.colorScheme == 3) {
      // Yellow (Over Collected)
      return('#FFE883')
      
    } else if (this.props.colorScheme == 4) {
      // Red (Over Collected)
      return('#DF8375')
    }
  }

  render() {
    return(
      <div className="">
      <ProgressBarComponent 
        id="linear"
        type='Linear'
        height='60'
        trackThickness={12}
        progressThickness={12}
        trackColor={this.getSecondaryColor()}
        progressColor={this.getPrimaryColor()}
        value={this.props.value * 100}
        animation={{
          enable: true,
          duration: 2000,
          delay: 0
        }}
      />
      </div>
    )
  }
}