import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { cardio } from 'ldrs';

// Custom Components
import PatientSimple from '../components/PatientSimple';
import { AddPatient } from '../modals/AddPatient';
import { ComplianceTriageObject } from '../components/ComplianceTriageObject';

// Custom Utils & Configurations
import { GET_PATIENTS_PATH, GET_ACTIVE_SESSIONS_PATH, INFO_KEY, PORTAL_PAGE_PATH, LOGIN_PAGE_PATH, PERMISSIONS } from '../../../resources/Vault';
import { getResultList } from '../../core/utils/get-search-results';
import { addSessionData } from '../utils/add-patient-sessions';
import { createGetRequest } from '../../auth/utils/axios-utils';

/**
 * Portal main class
 */
class Portal extends Component {
  
  constructor() {
    super();
    this.state = {
      patients: [],
      originalPatientSet: [],
      info: JSON.parse(localStorage.getItem(INFO_KEY)),
      showModal: false,
      showAddPatientSuccessToast: false,
      complianceData: [],
    };
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleOnExit = this.handleOnExit.bind(this);
    this.getSearchResultList = this.getSearchResultList.bind(this);
    cardio.register();
  };

  /**
   * Function which updates the patient list after a patient is added
   * @param {Object} patient New patient information
   */
  handleOnClose (patient) {
    this.setState({
      ...this.state,
      showModal: false,
      patients: [...this.state.patients, patient],
      originalPatientSet: [...this.state.originalPatientSet, patient]
    });
  };

  /**
   * Sets page title, checks auth, check for patient being removed
   */
  async componentDidMount() {
    document.title = "Portal | Patient Management Portal";
    sessionStorage.clear();

    // Configure the API requests
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const getPatientsResponse = await createGetRequest(GET_PATIENTS_PATH, headers);
    const getSessionsResponse = await createGetRequest(GET_ACTIVE_SESSIONS_PATH, headers);

    if(getPatientsResponse.auth) {
      // Add the session data to the patients and set state
      const patients = addSessionData(getPatientsResponse.patients, getSessionsResponse);
      this.setState({
        info: JSON.parse(localStorage.getItem(INFO_KEY)),
        patients: patients,
        originalPatientSet: patients,
        complianceData: getSessionsResponse,
      });
    } else {
      this.props.history.push({ pathname: LOGIN_PAGE_PATH });
      return;
    }

    // Check to see if a patient is getting removed
    if(this.props.match.params.pid) {
      var temp = this.props.match.params.pid;
      var filtered = this.state.patients.filter(function(item) { 
        return item.pid !== temp;  
      });
      this.setState({patients: filtered});
      this.props.history.replace({pathname: PORTAL_PAGE_PATH});
    }
  };

  /**
   * Function which closes the add patient modal without 
   * adding a new patient
   */
  handleOnExit () {
    this.setState({...this.state,showModal: false});
  };

  /**
   * Function which updates the patients list based on a search term
   */
  getSearchResultList(searchTerm) {
    this.setState({...this.state, patients: getResultList(this.state.originalPatientSet, searchTerm)});
  }

  render() {
    if (this.state.info.permission == PERMISSIONS.OWNER || this.state.info.permission == PERMISSIONS.ADMIN || this.state.info.permission == PERMISSIONS.USER) {
    return (
      <section className="flex flex-row h-screen -mb-16 w-full pl-4">
        {/* Section for the Compliance Triage */}
        <section className="flex flex-col w-1/5 mr-2 mb-16 mt-4">
          <label className="w-full text-center text-3xl font-bold pb-2 border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2]">
            Compliance Triage
          </label>

          {/* Compliance Container */}
          <div className="mt-2 overflow-auto px-2">
            {!this.state.complianceData.length == 0 ?
              this.state.complianceData.map(block => <ComplianceTriageObject key={block.rules.title} {...block} />)
              : 
              <div className="w-full flex justify-center mt-10">
                <l-cardio size="80" stroke="6" speed="2" color="black" />
              </div>
            }
          </div>
        </section>

        {/* Section for the patient information */}
        <section className="flex flex-col w-4/5 ml-2 px-4 mb-16 border-l-2 border-black">
          <div className="bg-[#E5E4E2] rounded mt-4">
            <div className="flex justify-center">
              <label className="text-3xl font-bold">
                Patient View
              </label>
            </div>

            {/* Info / Search / Add Patient Section */}
            <div className="w-full flex flex-row mt-8 px-4">
              <div className="w-1/2 flex flex-col">
                {/* User Management and info block */}
                <label className="text-2xl font-bold">Patient Management</label>
                <label className="text-md">View patients at a glance, or add a new patient</label>
              </div>
              <div className="w-1/2 flex flex-row-reverse">
                {/* Add user button */}
                { this.state.info.permission != 'user' ? 
                  <button
                    className="w-2/5 h-full  rounded-md bg-primary hover:bg-accent text-white"
                    onClick={() => this.setState({...this.state, showModal: true})}>
                    Add Patient
                  </button>
                  : null }
                {/* Search Bar */}
                <input
                  type="Search"
                  id="Search"
                  className=" text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50 mr-2"
                  placeholder="Search"
                  autoComplete="off"
                  readOnly
                  onChange={event => this.getSearchResultList(event.target.value)} />
              </div>
            </div>
            
            {/* Column Descriptions */}
            <div>
              <div className="w-full flex flex-row border-b-4 border-[#D3D3D3] mt-4 shadow-lg">
                <div className="w-1/6 text-center">
                  <label className="text-lg">Name</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Date of Birth</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Sex</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Weight</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Height</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Session Status</label>
                </div>
              </div>
            </div>
          </div>
          
          {/* Patient View Section */}
          <div className="rounded-md overflow-auto">
            <div className="flex flex-col divide-y-2">
              { this.state.patients.length == 0 ? 
                  <div className="w-full flex justify-center mt-10">
                    <l-cardio size="80" stroke="6" speed="2" color="black" />
                  </div>
                : this.state.patients.map(block => <PatientSimple key={block.id} {...block} />)
              }
            </div>
          </div>
        </section>

        <AddPatient
          visible={this.state.showModal}
          handleOnClose = {this.handleOnClose}
          addPatient = {this.addPatient}
          handleOnExit = {this.handleOnExit}
        />
      </section>
    )
    } else {
      return(
        <div className="flex flex-col h-screen w-full pl-4 mt-10">
          <label className="text-6xl font-extrabold text-primary text-center"> 
            Contact Your System Administrator 
          </label>
          <div className="text-2xl text-center text-gray-500 mt-6">
            Looks like your permissions haven't been updated yet. An orginization owner will need to give you permission to view this page
          </div>
        </div>
        
      )
    }
  }
}

export default withRouter(Portal);
