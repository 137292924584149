import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { PATIENT_DATA_PAGE_PATH } from '../../../resources/Vault';


/**
 * Component which renders an overview of a  single patient's data
 */
class PatientSimple extends Component {
  constructor(props) {
    super(props);
    this.routeToPatient = this.routeToPatient.bind(this);
  };

  /**
   * Creates uri with base patient data page adding PID and
   * redirects to patient data page
   */
  routeToPatient() {
    sessionStorage.setItem('data', JSON.stringify(this.props));
    let path = PATIENT_DATA_PAGE_PATH + this.props.pid.toString();
    this.props.history.push({pathname: path});
  }
  
  render() {
    if(!this.props.firstName) {
      return null;
    }
    return (
      <button 
        className="w-full flex flex-row py-4 hover:bg-[#FFFFF0]"
        onClick={this.routeToPatient}>
        <div className="w-1/6 text-center">
          <label className="text-lg">{this.props.firstName} {this.props.lastName}</label>
        </div>
        <div className="w-1/6 text-center">
          <label className="text-lg"> {this.props.dob} </label>
        </div>
        <div className="w-1/6 text-center">
          <label className="text-lg"> {this.props.sex} </label>
        </div>
        <div className="w-1/6 text-center">
          <label className="text-lg">{this.props.weight}</label>
        </div>
        <div className="w-1/6 text-center">
          <label className="text-lg"> {this.props.height} </label>
        </div>
        {
          this.props.sessions ?
            <div className="w-1/6 text-center flex flex-row justify-center">
              <div className="px-2 h-full bg-green-400 rounded-full mr-2">
                <label className="text-lg"> {this.props.sessions.compliant} </label>
              </div>
              <div className="px-2 h-full bg-red-400 rounded-full ml-2">
                <label className="text-lg"> {this.props.sessions.nonCompliant} </label>
              </div>
            </div>
          :
            <div className="w-1/6 text-center">
              <label className="text-lg"> No Active Sessions </label>
            </div>
        }
      </button>
    );
  }
};

export default withRouter(PatientSimple);
