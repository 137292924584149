import React, { Component } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, 
    Inject, Legend, Category, Tooltip, DataLabel, HistogramSeries } from '@syncfusion/ej2-react-charts';

/**
 * Generic histogram with configurable properties
 * @property {string} labels.title Title of the graph
 * @property {string} labels.dataKeyX X-axis data key
 * @property {string} labels.dataKeyY Y-axis data key
 * @property {Array<string>} data IMU data
 */
class Histogram extends Component {
    constructor(props) {
        super(props);
        this.primaryxAxis = {
            majorGridLines: { width: 0 },
            title: props.labels.x,
            minumum: 0,
            maximim: 25
        };
        this.primaryyAxis = { title: props.labels.y,
            minimum: 0,
            interval: 3,
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 }
        };
        this.legendSettings = { visible: true, position: 'Right' };
        this.tooltipsettings = { enable: true };
        this.marker = {
            dataLabel: {
                visible: true,
                position: 'Top',
                font: { fontWeight: '1000', color: '#ffffff' }
            }
        };
        this.titlestyle = {
            fontFamily: "Arial",
            fontWeight: 'bold',
            color: "#04223A",
            size: '23px'
          };
    }

    render() {
        return(
            <>
                <ChartComponent
                    primaryXAxis={this.primaryxAxis}
                    primaryYAxis={this.primaryyAxis}
                    tooltip={this.tooltipsettings}
                    legendSettings={this.legendSettings}
                    title={this.props.labels.title}
                    titleStyle={this.titlestyle}>
                    <Inject services={[HistogramSeries, Legend, Tooltip, Category, DataLabel]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective
                            dataSource={this.props.data}
                            yName={this.props.labels.dataKeyY}
                            name='Shake Count'
                            type='Histogram'
                            fill="#F5A623"
                            marker={this.marker}
                            showNormalDistribution={true}
                            columnWidth={0.99}
                            binInterval={3}>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </>
        );
    }
}

export { Histogram };
