import {React, Component} from 'react';
import { RightTooltip} from '../../../core/components/RightTooltip';
import { LeftTooltip } from '../../../core/components/LeftTooltip';
import { generate_file } from '../../utils/data-download';
import { DATA_KEY } from '../../../../resources/Vault';

/**
 * Component to display detailed data from a graph datapoint
 */
class GranularData extends Component {
  constructor(props) {
    super(props);
    /**
     * GranularData component state
     * @type {Object}
     * @property {string} date Date of the dataset
     * @property {Object} data Granular data to display
     * @property {boolean} flagged Was the dataset flagged by the Balance API?
     */
    this.state = {
      date: props.date,
      data: this.props.data,
      flaggedData: this.props.flagged
    };
    this.closeGranularData = this.closeGranularData.bind(this);
    this.downloadData = this.downloadData.bind(this);
  }

  /**
   * Close the granular data component and reset the date back to empty
   */
  closeGranularData() {
    this.props.showGranularData(false, "", [], false);
  }

  /**
   * Creates a file name based on patient data and triggers file
   * to download
   */
  downloadData() {
    // Format date as ISO 8601
    let date = new Date(this.state.data.date).toISOString();
    // Replace colons with dashes
    date = date.replaceAll(':', '-');
    // Chop off the milliseconds
    date = date.slice(0, -5);

    // Get patient data from session storage
    const patient = JSON.parse(sessionStorage.getItem(DATA_KEY));

    const filename = patient.firstName + "-" + patient.lastName
                      + "-" + this.state.data.side + "-" + this.state.data.exercise
                      + "-" + date;
    
    // Format data for download
    const payload = {
      data: this.state.data.data
    };
    generate_file(filename, payload);
  }

  render() {
    return(
      <div className="border-4 border-black rounded-lg">
        {/* This is the top bar for the granular data */}
        <div className="flex justify-between mx-5">
          <div>
            <label  className="font-normal text-gray-900 mr-1">
              Granular Data For: 
            </label>
            <label className="font-semibold text-gray-900">
              {this.state.date}
            </label>
          </div>
          <button 
            className="font-semibold text-center text-xl text-gray-700 w-7 rounded-xl hover:bg-red-400 "
            onClick={() => this.closeGranularData()}>
              X
          </button>
        </div>

        {/* This is the main data for being shown in the granular data component */}
        <div className="flex justify-between pt-5 px-3">
          <div>
            <div className="flex">
              <label className="font-normal text-gray-900"> Form: </label>
              <label className="ml-1 mr-2 font-semibold text-gray-900">{parseFloat(this.state.data.form).toFixed(2)}</label>
              <RightTooltip message="Form score for this dataset"/>
            </div>
            <div className="flex">
              <label className="font-normal text-gray-900"> Classification: </label>
              <label className="ml-1 mr-2 font-semibold text-gray-900"> {this.state.data.classification} </label>
              <RightTooltip message="Gait classification by Balance AI"/>
            </div>
            <div className="flex">
              <label className="font-normal text-gray-900"> Confidence: </label>
              <label className="ml-1 mr-2 font-semibold text-gray-900"> {parseFloat(this.state.data.confidence).toFixed(2)}% </label>
              <RightTooltip message="Confidence of Balance API in its classification"/>
            </div>
          </div>
          <div>
            <div className="flex justify-center">
              <label className="font-semibold text-gray-900 mr-2"> Movement Distribution </label>
              <RightTooltip message="Percentage of movement across each plane"/>
            </div>
            <div className="flex justify-center">
              <label className="font-normal text-gray-900"> Saggital: </label>
              <label className="font-semibold text-gray-900"> {parseFloat(this.state.data.acc_distribution[0] * 100).toFixed(2)}% </label>
            </div>
            <div className="flex justify-center">
              <label className="font-normal text-gray-900"> Coronal: </label>
              <label className="font-semibold text-gray-900"> {parseFloat(this.state.data.acc_distribution[1] * 100).toFixed(2)}% </label>
            </div>
            <div className="flex justify-center">
              <label className="font-normal text-gray-900"> Axial: </label>
              <label className="font-semibold text-gray-900"> {parseFloat(this.state.data.acc_distribution[2] * 100).toFixed(2)}% </label>
            </div>
          </div>
          <div>
            <div className="flex">
              <LeftTooltip message="Side of the body the Balance Device was worn on for this dataset"/>
              <label className="ml-2 font-normal text-gray-900"> Side Worn: </label>
              <label className="ml-1 font-semibold text-gray-900"> {this.state.data.side} </label>
            </div>
            <div className="flex">
              <LeftTooltip message="Gait velocity for this dataset"/>
              <label className="ml-2 font-normal text-gray-900"> Gait Velocity: </label>
              <label className=" ml-1 font-semibold text-gray-900"> {this.state.data.gait_velocity} </label>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer" onClick={() => this.downloadData()}>
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>


          </div>
        </div>
        <div className="my-5 mx-3">
          {this.state.flaggedData ? 
            <h1> Data Was Flagged Because: </h1> 
          : null}
          {this.state.data.gait_velocity < 0.5 ? 
            <label className="font-semibold text-red-500"> *  Velocity Too Low  * </label> 
          : null}
          {this.state.data.gait_velocity > 1.2 ? 
            <label className="font-semibold text-red-500"> *  Velocity Too High  * </label> 
          : null}
          {this.state.flaggedData && this.state.data.evaluation.confidence ? 
            <label className="font-semibold text-red-500"> *  Model Confidence Too Low  * </label> 
          : null}
          {this.state.flaggedData && this.state.data.evaluation.classification ? 
            <label className="font-semibold text-red-500"> *  Classification is inconsistent  * </label>
          : null}
        </div>
      </div>
    )
  }
}

export { GranularData };
