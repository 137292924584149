import React from 'react';


/**
 * Simple medication visual displaying name, start and end dates
 * @param {Object} props Medication Object
 * @returns Medication component displaying props
 */
function Medication(props) {
  return(
    <>
      <div className="hover:bg-gray-300 border-4 border-black rounded-3xl w-full p-3">
        <label className="flex justify-center font-semibold text-gray-900">{props.name}</label>
        <div className="flex justify-center ">
          <label className="font-normal text-gray-900"> Start Date:</label>
          <label className="font-semibold text-gray-900"> {props.start} </label>
        </div>
        <div className="flex justify-center">
          <label className="font-normal text-gray-900"> End Date:</label>
          <label className="font-semibold text-gray-900"> {props.end} </label>
        </div>
      </div>
    </>
  )
}

export default Medication;
