import React, { Component } from "react";

export class SessionSimple extends Component {
  constructor(props){
    super(props);
    this.formatTitle = this.formatTitle.bind(this);
  }

  formatTitle() {
    if (this.props[0] === "gait_velocity") {
      return("Gait Velocity");
    } else if (this.props[0] === "form") {
      return("Form");
    }
  }

  render() {
    if(this.formatTitle() === this.props.activeGraph) {
      //Render an active component
      return(
        <div className = "w-full border-4 border-black rounded-lg h-1/5 mt-3 cursor-pointer" onClick={() => {this.props.setGraph(this.formatTitle())}}>
          <div className="w-full">
            <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2 cursor-pointer">{this.formatTitle()}</label>
          </div>
          <div className="flex mb-2 px-1">
            <div className="w-1/3">
              <label className="font-semibold flex justify-center text-gray-700 cursor-pointer">Average: {parseFloat(this.props[1].avg).toFixed(2)}</label>
            </div>
            <div className="w-1/3">
              <label className="font-semibold flex justify-center text-gray-700 cursor-pointer">Min: {parseFloat(this.props[1].min).toFixed(2)}</label>
            </div>
            <div className="w-1/3">
              <label className="font-semibold flex justify-center text-gray-700 cursor-pointer">Max: {parseFloat(this.props[1].max).toFixed(2)}</label>
            </div>
          </div>
        </div>
      )
    } else {
      // Render a non-active component
      return(
        <div className = "w-full border-2 border-black rounded-lg h-1/5 mt-3 hover:border-4 cursor-pointer" onClick={() => {this.props.setGraph(this.formatTitle())}}>
          <div className="w-full">
            <label className="font-semibold flex justify-center text-lg text-gray-700 mt-2 cursor-pointer">{this.formatTitle()}</label>
          </div>
          <div className="flex mb-2 px-1">
            <div className="w-1/3">
              <label className="font-semibold flex justify-center text-gray-700 cursor-pointer">Average: {parseFloat(this.props[1].avg).toFixed(2)}</label>
            </div>
            <div className="w-1/3">
              <label className="font-semibold flex justify-center text-gray-700 cursor-pointer">Min: {parseFloat(this.props[1].min).toFixed(2)}</label>
            </div>
            <div className="w-1/3">
              <label className="font-semibold flex justify-center text-gray-700 cursor-pointer">Max: {parseFloat(this.props[1].max).toFixed(2)}</label>
            </div>
          </div>
        </div>
      )
    }
  }
}