import { Category, DateTimeCategory, ChartComponent, LineSeries, TrendlineDirective, TrendlinesDirective, Trendlines, ColumnSeries, DataLabel, Inject, Legend, SeriesCollectionDirective, SeriesDirective, Tooltip } from '@syncfusion/ej2-react-charts';
import React, { Component } from "react";

/**
 * Generic bar chart with configurable properties
 * @property {string} labels.title Title of the graph
 * @property {string} labels.dataKeyX X-axis data key
 * @property {string} labels.dataKeyY Y-axis data key
 * @property {Array<string>} data IMU data
 */
class BarChart extends Component {
  constructor(props) {
    super(props);
    this.primaryxAxis = { valueType: 'DateTime' };
    this.titlestyle = {
      fontFamily: "Arial",
      fontWeight: 'bold',
      color: "#04223A",
      size: '23px'
    };
    this.legendSettings = { visible: true, position: 'Right' };
  }
  render () {
    return(
      <>
        <ChartComponent
          title={this.props.labels.title}
          primaryXAxis={{valueType:"DateTimeCategory", labelFormat:"yMMMdd"}}
          primaryYAxis={{title:this.props.labels.y}}
          legendSettings={this.legendSettings}
          titleStyle={this.titlestyle}>
          <Inject services={[ColumnSeries, Trendlines, Legend, LineSeries, Category, DateTimeCategory]}/>
          <SeriesCollectionDirective>
            <SeriesDirective
              type="Column"
              fill="#04223A"
              dataSource = {this.props.data}
              name = {this.props.labels.title}
              xName={this.props.labels.dataKeyX}
              yName={this.props.labels.dataKeyY}>
              <TrendlinesDirective>
                <TrendlineDirective type='Linear' width={2} name='Trend' visible={false} fill="#F5A623">
                </TrendlineDirective>
              </TrendlinesDirective>
            </SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </>
    );
  }
}

export { BarChart };
