import React, { Component } from "react";
import { ChartComponent, DateTimeCategory, TrendlineDirective, TrendlinesDirective, Trendlines, SeriesCollectionDirective, SeriesDirective, Inject, ColumnSeries, Legend, Category, Tooltip, DataLabel, LineSeries } from '@syncfusion/ej2-react-charts';

/**
 * Acceleration distribution bar graph
 */
class AccDistribution extends Component {
    constructor(props) {
        super(props);
        /**
         * AccDistribution component state
         * @type {Object}
         * @property {Array<string>} data IMU Data
         */
        this.state = {
            data: props.data
        };
        this.palette = [ "#04223A", "#14ACAA", "#F5A623"];
        this.primaryxAxis = { valueType: 'DateTimeCategory', labelFormat:'yMMMdd' };
        this.primaryyAxis = { title: 'Movement Plane Distribution Percentage' };
        this.legendSettings = { visible: true, position: 'Right' };
        this.titlestyle = {
          fontFamily: "Arial",
          fontWeight: 'bold',
          color: "#04223A",
          size: '23px'
        };
    }
    render() {
      return (
        <ChartComponent 
        id='charts'
        palettes={this.palette}
        primaryXAxis={this.primaryxAxis}
        primaryYAxis={this.primaryyAxis}
        legendSettings={this.legendSettings}
        titleStyle={this.titlestyle}
        title='Movement Plane Distribution'>
          <Inject services={[ColumnSeries, Legend, Trendlines, Tooltip, DataLabel, LineSeries, Category, DateTimeCategory]}/>
          <SeriesCollectionDirective>
            <SeriesDirective dataSource={this.state.data} xName='date' yName='sagittal' name='Sagittal' type='Column'>
              <TrendlinesDirective>
                <TrendlineDirective type='Linear' width={2} name='Sagittal-Trend' visible={false} fill="#04223A">
                </TrendlineDirective>
              </TrendlinesDirective>
            </SeriesDirective>
            <SeriesDirective dataSource={this.state.data} xName='date' yName='axial' name='Axial' type='Column'>
              <TrendlinesDirective>
                <TrendlineDirective type='Linear' width={2} name='Axial-Trend' visible={false} fill="#14ACAA">
                </TrendlineDirective>
              </TrendlinesDirective>
            </SeriesDirective>
            <SeriesDirective dataSource={this.state.data} xName='date' yName='coronal' name='Coronal' type='Column'>
              <TrendlinesDirective>
                <TrendlineDirective type='Linear' width={2} name='Coronal-Trend' visible={false} fill="#F5A623">
                </TrendlineDirective>
              </TrendlinesDirective>
            </SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      )
    }
};

export { AccDistribution };
