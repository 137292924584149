/**
 * Formats the template data into labels for the dropdown
 * @param {Object} templates data Object holding all of the template information
 * @returns {Array} the value / label data for the template dropdown
 */
export function formatTemplateLabels(templates) {
    let ret = [];
    let value = "";
    let label = "";

    for(let i = 0; i < templates.length; ++i) {
        value = templates[i].title;
        label = templates[i].title;

        ret.push({
            value: value,
            label: label
        })
    }
    ret.push({
        value: "None",
        label: "--- NONE ---"
    })
    return ret
}

/**
 * Gets the template object that holds the data to be loaded in when a template is selected
 * @param {String} templateName Name of the template that will be displayed
 * @param {Object} templates data Object holding all of the template information
 * @returns {Object} an object containing all of the information to display
 */
export function getTemplateData(templateName, templates) {
    if(templateName === "None") {
        return(
            {
                metrics: [],
                period: "",
                min_threshold: ""
            }
        )
    }
    for(let i = 0; i < templates.length; ++i) {
        if(templates[i].title === templateName) {
            return(getRules(templates[i]));
        }
    }
}

/**
 * Formats the template data into an object to be consumed in the FE
 * @param {Object} template data Object holding all of the template information
 * @returns {Array} an array containing all of the information to display in the FE
 */
function getRules(template) {
    let metrics = template.metrics;
    let metricsArray = [];
    let ruleValues = {};
    let ruleObject = {};
    let metricTitle = "";
    let formattedTitle = "";

    Object.entries(metrics).map(block => {
        ruleValues = block[1];
        metricTitle = block[0];
        Object.entries(ruleValues).map(block2 => {
            if(metricTitle == "gait_velocity") {formattedTitle = "Gait Velocity"}
            else if(metricTitle == "form") {formattedTitle = "Form"}
            
            ruleObject = {
                rule: formattedTitle + " " + block2[0] + " " + block2[1].compare + " " + block2[1].value,
                metric: formattedTitle,
                statistic: block2[0],
                operator: block2[1].compare,
                value: block2[1].value
            };
            metricsArray.push(ruleObject);
        });
    });
    // Create the object to be returned
    let ret = {
        metrics: metricsArray,
        period: template.period,
        min_threshold: template.min_threshold
    }
    return ret;
}
