import React, { Component } from "react";
import { AUTH_TOKEN_KEY, DATA_KEY, REMOVE_USER_PATH, REMOVE_USER_TITLE } from '../../../resources/Vault';
import { ErrorToast }  from '../../core/components/ErrorToast';
import { showTooltip } from "@syncfusion/ej2-react-charts";
import { SuccessToast } from "../../core/components/SuccessToast";
import { createDeleteRequest } from '../../auth/utils/axios-utils';

class RemoveUserConfirmation extends Component{
  
  constructor(props) {
    super(props);
    this.state = {
      password:"",
      error: false,
      email: this.props.email,
      incorrectPassword: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
      opacity: false,
    };
    this.handleKeypress = this.handleKeypress.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.close = this.close.bind(this);
    this.hideErrToast = this.hideErrToast.bind(this);
    this.hideSuccToast = this.hideSuccToast.bind(this);
  }
  handleOnClose () {
    this.setState({...this.state,showModal: false})
  };

  /**
    * Hides success toast
    */
  hideSuccToast() {
    this.setState({...this.state, showAPISuccessToast: false});
  }

  /**
   * Hides error toast
   */
  hideErrToast() {
    this.setState({...this.state, showAPIErrorToast: false});
  }
  
  /**
   * Function which checks for errors and then closes the modal
   * If we have no errors then submit, else show error message
   */
  checkErrors () {
    if(this.state.password == ""){
      this.setState({...this.state,error:true})
    } else {
      this.onSubmit()
    }
  }

  /**
   * 
   * @param {*} e 
   * Function which detects the clicking of the enter key
   * When enter is clicked, then we call the checkErrors method which submits the modal
   */
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.checkErrors();
    }
  };

  /**
   * Function which calls the BE and submits the modals
   * We call the handle on close method of the parent component and close the modal
   */
  async onSubmit () {
    this.setState({...this.state, opacity: true});

    // Configure the remove user request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const data = {
      title: REMOVE_USER_TITLE,
      info:{
        email: this.state.email,
        password: this.state.password
      }
    };
    try {
      const removeUserResponse = await createDeleteRequest(REMOVE_USER_PATH, headers, data);
      this.setState({...this.state, opacity: false});
      this.props.handleRemove(removeUserResponse.email);
      this.setState({
        ...this.state,
        showAPIErrorToast: false,
        showAPISuccessToast: true,
      })
    } catch(error) {
      this.setState({...this.state, opacity: false});
      console.log(error);
      this.setState({
        ...this.state,
        passwordError: true,
        showAPIErrorToast: true,
        showAPISuccessToast: false,
      });
    }
  };

  close () {
    this.props.handleOnExit();
    this.setState({
      password: "",
      error: false,
      incorrectPassword: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
    })
  }

  render(){
    if (!this.props.visible) return null;
    return (
      <>
        <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white p-4 rounded">
            <div className="flex justify-center">
              <h1 className="font-semibold text-center text-xl text-gray-700 mx-16">
                Remove User
              </h1>
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-xl hover:bg-red-400"
                onClick={() => this.close()}>
                X
              </button>
            </div>
            <div className="relative">
              <div className="flex justify-center">
                <label className="justify-center font-medium text-gray-900">
                  Enter your password to confirm
                </label>
              </div>
              {!this.state.error ? 
                <input
                  onChange={event => this.setState({...this.state,password:event.target.value})}
                  onKeyPress={this.handleKeypress}
                  type="password"
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder="Password"
                />
              :
                <input
                  onChange={event => this.setState({...this.state,password:event.target.value})}
                  onKeyPress={this.handleKeypress}
                  type="password"
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder="Password"
                />
              }
              {this.state.incorrectPassword ? <label className="font-semibold text-center text-red-400">* Incorrect Password</label> : null}
            </div>

            <button 
              className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
              onClick={this.checkErrors}>Remove
            </button>
          </div>
          <div className="absolute bottom-0 right-0 mr-10 mb-6">
            {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue removing this user"} visible={this.state.showAPIErrorToast} hide={this.hideErrToast}/> : null}
            {this.state.showAPISuccessToast ? <SuccessToast message={"User removed"} visible={this.state.showAPISuccessToast} hide={this.hideSuccToast}/> : null}
          </div>
        </div>
      </>
    )
  }
}
export default (RemoveUserConfirmation);
