/**
 * Removes a provided value from an array
 * @param {Array} array The array to be spliced
 * @param {String} value The value to be removed from the array
 * @returns {Array} The array after the given value has been removed
 */
export function removeFromArray(array, value) {
    for(let i = 0; i < array.length; i++) {
        if(array[i].id === value) {
            array.splice(i, 1);
        }
    }
    return array;
}