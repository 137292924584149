import {React, Component} from 'react';
import { LineChart } from './LineChart';
import { AccDistribution } from './AccDistribution';
import { BarChart } from './BarChart';
import { Histogram } from './Histogram';
import { Dropdown } from '../../../core/components/Dropdown';
import { GranularData } from './GranularData';
import { FORM_OVER_TIME, CLASSIFICATION_CONFIDENCE, TRIP_COUNT, SHAKE_COUNT, FORM_GRAPH, CLASSIFICATION_GRAPH} from '../../../../resources/Config';

/**
 * Component to display different type of charts
 * @property {Object} data Balance Data
 * @property {string} default Key of the graph to display by default
 * @property {Object} graphSet Dropdown options to display a choice of graphs
 */
class Graph extends Component {

  constructor(props) {
    super(props);
    /**
     * Graph component state
     * @type {Object}
     * @property {string} graph Graph to display
     * @property {boolean} granularDataOn Display granular data component?
     * @property {string} granularDataDate Date of dataset
     * @property {string} granularData Granular data to display in component
     * @property {boolean} flaggedData Was the data flagged by Balance API
     */
    this.state = {
      graph: props.default,
      granularDataOn: false,
      granularDataDate: "",
      granularData: [],
      flaggedData: false,
    };

    this.changeGraph = this.changeGraph.bind(this);
    this.formatForAcc = this.formatForAcc.bind(this);
    this.formatForHistogram = this.formatForHistogram.bind(this);
    this.showGranularData = this.showGranularData.bind(this);
  }

  /**
   * Changes the graph to display
   * @param {string} graph Graph to display
   */
  changeGraph(graph) {
    this.setState({...this.state, graph: graph});
  }

  /**
   * Shows the granular data component
   * @param {boolean} show Show component
   * @param {string} date Date of data to display
   * @param {Object} data Data to display
   * @param {boolean} flagged Data flagged?
   */
  showGranularData(show, date, data, flagged) {
    this.setState({...this.state, 
      granularDataOn: show,
      granularDataDate: date,
      granularData: data,
      flaggedData: flagged
    });
  }

  /**
   * Formats distribution as percentages
   * @param {Array<Object>} data Exercise Data Array
   * @returns Formatted exercise data array
   */
  formatForAcc(data) {
    let acc_data = [];
    for (var i=0; i<data.length; i++) {
      var set = data[i];
      var temp = {
        date: set.date,
        sagittal: 100 * set.acc_distribution[0],
        axial: 100 * set.acc_distribution[1],
        coronal: 100 * set.acc_distribution[2]
      };
      acc_data = [...acc_data, temp];
    }
    return acc_data;
  }

  /**
   * Filters out data that is respiratory related
   * @param {Array<Object>} data Exercise Data Array
   * @returns Filtered array of Objects
   */
  formatForHistogram(data) {
    let hist_data = [];
    for (var i=0; i<data.length; i++) {
      var tmp = data[i];
      // Ensure that the dataset is relevant
      if(tmp.exercise !== 'sumo') continue;
      hist_data.push({ y: tmp.shake_count });
    }
    return hist_data;
  }

  render() {
    return(
      <>
        <div className="items-center justify-center px-4 max-h-full h-screen w-full mx-4 rounded-3xl overflow-auto">
          <div className="rounded-3xl mx-4">
            {this.state.graph == "accDistribution" ? <AccDistribution data={this.formatForAcc(this.props.data)} /> : null}
            {this.state.graph == "formOverTime" ? 
                <LineChart 
                  data={this.props.data} 
                  labels={FORM_OVER_TIME} 
                  name={FORM_GRAPH}
                  legendSide={'Right'}
                  showGranularData={this.showGranularData}/> : null
            }
            {this.state.graph == "classificationConfidence" ? 
                <LineChart 
                  data={this.props.data} 
                  labels={CLASSIFICATION_CONFIDENCE} 
                  name={CLASSIFICATION_GRAPH}
                  legendSide={'Right'}
                  showGranularData={this.showGranularData}/> : null
            }
            {this.state.graph == "tripCount" ? <BarChart data={this.props.data} labels={TRIP_COUNT} /> : null}
            {this.state.graph == "inhalerShake" ? <Histogram data={this.formatForHistogram(this.props.data)} labels={SHAKE_COUNT}/> : null}
            {!this.state.granularDataOn ?   
              <div className="flex justify-center pb-24 mt-4">
                <Dropdown options={this.props.graphSet}
                          setValue={this.changeGraph}
                          buttonStyle="flex justify-center border-4 border-black rounded-lg w-64 p-3"
                          menuStyle="max-h-24 overflow-auto border-l-2 border-r-2 border-b-2 border-black rounded-lg w-64 p-1"/>
              </div>
            : <GranularData 
                date={this.state.granularDataDate}
                showGranularData={this.showGranularData}
                data={this.state.granularData}
                flagged={this.state.flaggedData}/> }
          </div>
        </div>
      </>
    );
    }
};

export default Graph;
