import React, { Component} from "react";
import { withRouter } from 'react-router';


/**
 * Clears local and session storage then redirects to the login page
 */
class Logout extends Component {
    componentWillMount() {
        localStorage.clear();
        sessionStorage.clear();
        this.props.history.push('/login');
    }
    render() {
        return null;
    }
}

export default withRouter(Logout);
