import React, { Component } from 'react';

export class RightTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: this.props.message,
      showTooltip: false,
    }
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // Closes the tooltip if you click outside of it
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({...this.state,showTooltip: false});
    }
  }

  render() {
    return(
      <div ref={this.wrapperRef}>
        <div class="group relative w-max">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#A6A6A6" class="w-5 h-5 cursor-pointer" onClick={() => this.setState({...this.state,showTooltip: !this.state.showTooltip})}>
            <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"/>
          </svg>
          {this.state.showTooltip ? 
            <div class="absolute opacity-0 transition-opacity left-5 opacity-100 z-40 border border-gray-400 rounded-lg bg-gray-300 w-64 max-h-32 overflow-auto overscroll-none shadow-2xl p-2">
              <label className="font-normal text-gray-900">
                {this.state.message}
              </label>
            </div>
          :
          null }
        </div>
      </div>
    );
  }
}
