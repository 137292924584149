import React from "react";
import Logo from "./logo";
import { Footer } from 'flowbite-react';
import { BsInstagram, BsFacebook, BsTwitter } from 'react-icons/bs';
import { openInNewTab } from '../utils/new-tab';

/**
 * Balance Footer
 * @returns Custom footer component
 */
function BalanceFooter() {

  return (
    <>
      <Footer container> 
        <Logo/>
        <Footer.Copyright
          by="MotionFit™ Inc."
          href="#"
          year={new Date().getFullYear()}
        />
        <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-right">
          <div className="cursor-pointer">  
            <Footer.Icon
              onClick={() => openInNewTab("https://www.instagram.com/motionfit.co/")}
              icon={BsInstagram}
            />
          </div>
          <div className="cursor-pointer">
            <Footer.Icon
              onClick={() => openInNewTab("https://www.facebook.com/MotionFit.co")}
              icon={BsFacebook}
            />
          </div>
          <div className="cursor-pointer">
            <Footer.Icon
              onClick={() => openInNewTab("https://mobile.twitter.com/motionfiti")}
              icon={BsTwitter}
            />
          </div>
        </div>
      </Footer>
    </>
  );
}

export default BalanceFooter;
