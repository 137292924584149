import React, { Component } from 'react';

export class SessionMetric extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  };
  render() {
    if(undefined === this.props.metric) {
      return null;
    }
    if (this.props.isRange) {
      return (
        <div className="">
          <div className="hover:bg-gray-300 border-2 border-gray-300 rounded-lg w-full mt-2 py-3">
            <div className="flex flex-row justify-evenly">
  
              <div className="flex justify-center w-full">
                <div className="flex flex-row">
                <div className="mt-2">
                    <div className="flex justify-center">
                      <label className="font-semi-bold text-gray-900"> {this.props.value1}</label> 
                    </div>
                  </div>
                  <div className="mt-2 mx-2">
                    <div className="flex justify-center">
                      <label className="font-semi-bold text-gray-900"> {this.props.operator1}</label> 
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div className="mt-2 flex justify-center">
                  <label className="font-semi-bold text-gray-900"> {this.props.metric} </label> 
                </div>
              </div>
  
              <div className="flex justify-center w-full">
                <div className="flex flex-row">
                  <div className="mt-2">
                    <div className="flex justify-center">
                      <label className="font-semi-bold text-gray-900"> {this.props.statistic}</label> 
                    </div>
                  </div>
                  <div className="mt-2 mx-2">
                    <div className="flex justify-center">
                      <label className="font-semi-bold text-gray-900"> {this.props.operator2}</label> 
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-center">
                      <label className="font-semi-bold text-gray-900"> {this.props.value2}</label> 
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="w-full">
                <div className="flex justify-center mt-2 cursor-pointer" onClick={() => this.props.removeMetric(this.props.id)}>
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                    <path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)"></path><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)"></path>
                  </svg>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="">
          <div className="hover:bg-gray-300 border-2 border-gray-300 rounded-lg w-full mt-2 py-3">
            <div className="flex flex-row justify-evenly">
  
              <div className="w-full">
                <div className="mt-2 flex justify-center">
                  <label className="font-semi-bold text-gray-900"> {this.props.metric} </label> 
                </div>
              </div>
  
              <div className="flex justify-center w-full">
                <div className="flex flex-row">
                  <div className="mt-2">
                    <div className="flex justify-center">
                      <label className="font-semi-bold text-gray-900"> {this.props.statistic}</label> 
                    </div>
                  </div>
                  <div className="mt-2 mx-2">
                    <div className="flex justify-center">
                      <label className="font-semi-bold text-gray-900"> {this.props.operator}</label> 
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-center">
                      <label className="font-semi-bold text-gray-900"> {this.props.value}</label> 
                    </div>
                  </div>
                </div>
                </div>
  
              <div className="w-full">
                <div className="flex justify-center mt-2 cursor-pointer" onClick={() => this.props.removeMetric(this.props.id)}>
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                    <path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)"></path><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)"></path>
                  </svg>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      );
    }
  }
};

