import React, { Component } from "react";
import { Dropdown } from '../../core/components/Dropdown';
import { MONTH_LABELS, DATE_LABELS, YEAR_LABELS, FEET_LABELS, INCHES_LABELS } from  '../../../resources/Config';
import { ADD_PATIENT_PATH, ADD_PATIENT_TITLE, AUTH_TOKEN_KEY } from '../../../resources/Vault';
import { ErrorToast }  from '../../core/components/ErrorToast';
import { SuccessToast } from '../../core/components/SuccessToast';
import { createPostRequest } from '../../auth/utils/axios-utils';

export class AddPatient extends Component{
  constructor(props) {
    super(props);
    let d = new Date();
    this.state = {
      firstName:"",
      lastName:"",
      lishID:"",
      email:"",
      dob:"01/01/" + d.getFullYear(),
      height:"37",
      month: "Jan",
      day: "01",
      year: d.getFullYear(),
      feet:"3",
      inches: "1",
      weight:0,
      sex:"",
      error: false,
      submitting: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
      firstNameEmpty: false,
      lastNameEmpty: false,
      patientIDEmpty: false,
      emailEmpty: false,
      weightEmpty: false,
      sexEmpty: false,
      opacity: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.close = this.close.bind(this);
    this.setMonth = this.setMonth.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setYear = this.setYear.bind(this);
    this.setSex = this.setSex.bind(this);
    this.setFeet = this.setFeet.bind(this);
    this.setInches = this.setInches.bind(this);
    this.calcHeight = this.calcHeight.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.hideErrToast = this.hideErrToast.bind(this);
    this.hideSuccToast = this.hideSuccToast.bind(this);
  };

  /**
    * Hides success toast
    */
  hideSuccToast() {
    this.setState({...this.state, showAPISuccessToast: false});
  }

  /**
    * Hides error toast
    */
  hideErrToast() {
    this.setState({...this.state, showAPIErrorToast: false});
  }

  /**
   * Function which checks for errors and then closes the modal
   * If we have no errors then submit, else show error message
   */
  checkErrors () {
    this.setState({...this.state,submitting:true})
    if(this.state.firstName == "" | 
      this.state.lastName == "" |
      this.state.lishID == "" |
      this.state.email == "" |
      this.state.day == "" | 
      this.state.month == "" |
      this.state.year == "" | 
      this.state.feet == "" |
      this.state.inches == "" | 
      this.state.weight < 50 | this.state.weight > 500 |
      this.state.sex == ""
      ){
      this.setState({...this.state,
        firstNameEmpty: this.state.firstName == "",
        lastNameEmpty: this.state.lastName == "",
        patientIDEmpty: this.state.lishID == "",
        emailEmpty: this.state.email == "",
        weightEmpty: this.state.weight == "",
        sexEmpty: this.state.sex == "",

        error:true, 
        submitting:false
      })
    } else {
      this.onSubmit()
    }
  };

  /**
   * 
   * @param {*} e 
   * Function which detects the clicking of the enter key
   * When enter is clicked, then we call the checkErrors method which submits the modal
   */
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.checkErrors();
    }
  };

  /**
   * Function which submits the modals
   * We call the handle on close method of the parent component and close the modal
   */
  async onSubmit () {
    this.setState({...this.state, opacity: true});
    const patient = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      id: this.state.lishID,
      email: this.state.email,
      dob: this.state.dob,
      height: this.state.height,
      weight: this.state.weight,
      sex: this.state.sex
    }
    
    // Configure the Add Patient request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const body = { title: ADD_PATIENT_TITLE, info: patient }

    try {
      const getPatientsResponse = await createPostRequest(ADD_PATIENT_PATH, headers, body);

      this.setState({...this.state, opacity: false});
      this.props.handleOnClose(patient);
      this.resetModal();
    } catch(error) {
      console.log(error);
      this.setState({...this.state,showAPIErrorToast: true, submitting :false, opacity: false, showAPISuccessToast: false});
    }
  };

  /**
   * Function which closes the modal window
   * After closing the window, we reset state to default
   */
  close () {
    this.resetModal();
    this.props.handleOnExit();
  }

  resetModal() {
    let d = new Date();
    this.setState({
      firstName:"",
      lastName:"",
      lishID:"",
      dob: "01/01/" + d.getFullYear(),
      height:"37",
      month: "Jan",
      day: "01",
      year: d.getFullYear(),
      feet:"3",
      inches: "1",
      weight:0,
      sex:"",
      error: false,
      submitting:false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
    })
  }

  /**
   * Calculate Height from given params
   * @param {bool} inch Is inches? If not, assume feet
   * @param {string} val Measurement value 
   * @returns Height in inches
   */
  calcHeight(inch, val) {
    let height;
    if (inch) {
      height = parseInt(this.state.feet) * 12 + parseInt(val);
    } else {
      height = parseInt(val) * 12 + parseInt(this.state.inches);
    }
    return height;
  }

  /**
   * Format date given input
   * @param {int} param 0=MM, 1=DD, 2=YYYY
   * @param {string} num Given number to format
   * @returns Formatted date string
   */
  formatDate(param, num) {
    let date;
    switch (param) {
      case 0:
        date = num + "/" + this.state.day + "/" + this.state.year;
        break;
      case 1:
        date = this.state.month + "/" + num + "/" + this.state.year;
        break;
      case 2:
        date = this.state.month + "/" + this.state.day + "/" + num;
    }
    return date;
  }

  setMonth (month) {
    this.setState({...this.state,
      month: month,
      dob: this.formatDate(0, month)
    });
  }
  setYear (year) {
    this.setState({...this.state,
      year: year,
      dob: this.formatDate(2, year)
    });
  }
  setDate (date) {
    this.setState({...this.state,
      day: date,
      dob: this.formatDate(1, date)
    });
  }
  setSex (event) {
    this.setState({...this.state,
      sex: event.target.value,
      sexEmpty: false,
    });
  }
  setFeet (feet) {
    this.setState({...this.state,
      feet: feet,
      height: this.calcHeight(false, feet)
    });
  }
  setInches (inches) {
    this.setState({...this.state,
      inches: inches,
      height: this.calcHeight(true, inches)
    });
  }

  render(){
    if (!this.props.visible) return null;
    return (
      <>
        <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-lg flex justify-center items-center " +  (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white h-4/6 overflow-auto overscroll-none px-6 pb-6 rounded">
            <div className="sticky top-0 z-40 flex justify-end bg-white border-b-2 h-12 p-2 -mx-6">
              <h1 
                className="font-semibold text-center text-xl text-gray-700"
                >
                Add Patient
              </h1>
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 ml-16 rounded-lg hover:bg-red-400 "
                onClick={() => this.close()}>
                X
              </button>
            </div>
            <div className="relative mt-3">
              <label className="font-medium text-gray-900">
                First Name
              </label>
              {!this.state.firstNameEmpty ? 
              <input
                onChange={event => this.setState({...this.state,firstName:event.target.value})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-! text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="First Name"
              />
              :
              <input
                onChange={event => this.setState({...this.state,firstName:event.target.value, firstNameEmpty: false})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-! text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="First Name"
              />
              }
            </div>

            <div className="relative mt-3">
              <label className="font-medium text-gray-900">
                Last Name
              </label>
              {!this.state.lastNameEmpty ? 
              <input
                onChange={event => this.setState({...this.state,lastName:event.target.value})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Last Name"
              />
              :
              <input
                onChange={event => this.setState({...this.state,lastName:event.target.value, lastNameEmpty: false})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Last Name"
              />
              }
            </div>

            <div className="relative mt-3">
              <label className="font-medium text-gray-900">
                Patient ID
              </label>
              {!this.state.patientIDEmpty ? 
              <input
                onChange={event => this.setState({...this.state,lishID:event.target.value})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Patient ID"
              />
              :
              <input
                onChange={event => this.setState({...this.state,lishID:event.target.value, patientIDEmpty: false})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Patient ID"
              />
              }
            </div>

            <div className="relative mt-3">
              <label className="font-medium text-gray-900">
                Patient Email
              </label>
              {!this.state.emailEmpty ?
                <input
                  onChange={event => this.setState({...this.state,email:event.target.value})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder="Email"
                />
              :
                <input
                  onChange={event => this.setState({...this.state,email:event.target.value, emailEmpty: false})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder="Email"
                />
              }
            </div>

            <div className="relative mt-3">
              <label className="font-medium text-gray-900">
                Date of Birth
              </label>
              <div className="flex">
                <div className="relative w-1/3 mr-1">
                  <Dropdown 
                    options={MONTH_LABELS}
                    setValue={this.setMonth}/>
                </div>
                <div className="relative w-1/3 mr-1">
                  <Dropdown 
                    options={DATE_LABELS} 
                    setValue={this.setDate}/>
                </div>
                <div className="relative w-1/3 mr-1">
                  <Dropdown 
                    options={YEAR_LABELS}
                    setValue={this.setYear}/>
                </div>
              </div>
            </div>

            <div className="relative mt-3">
              <label className="font-medium text-gray-900">
                Height
              </label>
              <div className="flex">
                <div className="relative w-1/2 mr-1">
                  <Dropdown 
                    options={FEET_LABELS} 
                    setValue={this.setFeet}
                    buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full p-3 hover:bg-gray-300"
                    menuStyle="absolute z-40 bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"/>
                </div>
                <div className="relative w-1/2 mr-1">
                  <Dropdown 
                    options={INCHES_LABELS} 
                    setValue={this.setInches}
                    buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full p-3 hover:bg-gray-300"
                    menuStyle="absolute z-40 bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"/>
                </div>
              </div>
            </div>
            
            <div className="relative mt-3">
              <label className="font-medium text-gray-900">
                Weight
              </label>
              {!this.state.weightEmpty ?
                <input
                  onChange={event => this.setState({...this.state,weight:event.target.value})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder="Weight"
                />
              :
                <input
                  onChange={event => this.setState({...this.state,weight:event.target.value, weightEmpty: false})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder="Weight"
                />
              }
            </div>

            
            <div className="relative mt-3 mb-2">
              <label className="font-medium text-gray-900">
                Sex
              </label>
              {!this.state.sexEmpty ? 
              <div onChange={this.setSex}>
                <input className="font-medium text-gray-900 hover:shadow-inner" type="radio" value="Male" name="gender" /> Male
                <input className="font-medium text-gray-900 ml-3 hover:shadow-inner" type="radio" value="Female" name="gender" /> Female
              </div>
              :
              <div className = "ring outline-none ring-4 ring-opacity-50 ring-red-600" onChange={this.setSex}>
                <input className="font-medium text-gray-900 hover:shadow-inner" type="radio" value="Male" name="gender" /> Male
                <input className="font-medium text-gray-900 ml-3 hover:shadow-inner" type="radio" value="Female" name="gender" /> Female
              </div>
              }
            </div>

            {this.state.error ? <label className="font-semibold text-center text-red-400">* Fields Cannot Be Blank</label> : null}
            {!this.state.submitting ? 
            <button 
              className="mt-1 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
              onClick={() => this.checkErrors()}>
                Submit
            </button>
            :
            <button
              type="button"
              className="mt-1 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary w-full h-12 text-base font-small leading-6 text-white opacity-50" disabled>
                    <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                Submitting...
            </button>
            }
            <div className="absolute bottom-0 right-0 mr-10 mb-6">
              {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue adding this patient"} visible={this.state.showAPIErrorToast} hide={this.hideErrToast}/> : null}
              {this.state.showAPISuccessToast ? <SuccessToast message={"Patient Added"} visible={this.state.showAPISuccessToast} hide={this.hideSuccToast}/> : null}
            </div>
          </div>
        </div>
      </>
    )
  }
}
