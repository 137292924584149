/**
 * Function which takes the list of patients and active session data and returns
 * a new list of patients with added session data if necessary
 * @param {Object} patients the list of all of the patients
 * @param {Object} sessionData all of the active session data
 * @returns {Object} the updated list of patients with added session data
 */
export function addSessionData(patients, sessionData) {
  // Convert array to an object to add sessions to patients by PID
  const patientMap = patients.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});

  // Loop through all of the sessions
  sessionData.filter(session => {
    // sessionStatus is true for compliant sessions and false for non-compliant sessions
    let sessionStatus = !session.evaluation;

    // Check if the patient for the session has a session object already
    if(patientMap[session.pid]["sessions"]) {
      patientMap[session.pid]["sessions"] = updateSessionStatus(patientMap[session.pid]["sessions"], sessionStatus);
    } else {
      // Create a new sessions object
      let sessionsObject = {
        "compliant" : 0,
        "nonCompliant": 0
      };
      patientMap[session.pid]["sessions"] = updateSessionStatus(sessionsObject, sessionStatus);
    }
  });

  // Convert the patient map back to an array for the UI to consume
  return Object.values(patientMap);
}

/**
 * Helper function which updates a sessions object
 * @param {Object} sessionsObject the sessions object to be modified
 * @param {boolean} sessionStatus boolean which determins which session status to increment
 * @returns {Object} the updated sessions object
 */
function updateSessionStatus(sessionsObject, sessionStatus) {
  if(sessionStatus) {
    sessionsObject["compliant"] += 1;
  } else {
    sessionsObject["nonCompliant"] +=1;
  }
  return sessionsObject;
}