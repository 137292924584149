import React, { Component } from "react";
import axios from 'axios';
import { LineChart } from '../components/charts/LineChart';
import { FORM_OVER_TIME, FORM_GRAPH, GAIT_VELOCITY_GRAPH, GAIT_VELOCITY_GRAPH_NAME } from '../../../resources/Config';
import { get_progress } from '../../core/utils/session-collection-progress';
import { ComplianceBucket } from "../components/ComplianceBucket";
import { SetsCollectedProgressBar } from "../components/SetsCollectedProgressBar";
import { SessionCompletionProgressBar } from '../components/SessionCompletionProgressBar';
import { AUTH_TOKEN_KEY } from "../../../resources/Vault";
import { ClickToConfirm } from "../modals/ClickToConfirm";
import { ErrorToast }  from '../../core/components/ErrorToast';
import { SuccessToast } from "../../core/components/SuccessToast";
import { createGetRequest } from '../../auth/utils/axios-utils';

export class SessionViewModal extends Component {
  constructor(props) {
    super(props);
    this.state= {
      dataRecieved: false,
      data: [],
      graph: "Form",
      showConfirmModal: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
    };
    this.close = this.close.bind(this);
    this.getGraphData = this.getGraphData.bind(this);
    this.setGraph = this.setGraph.bind(this);
    this.formatForAcc = this.formatForAcc.bind(this);
    this.getDaysSinceStartDate = this.getDaysSinceStartDate.bind(this);
    this.deleteSession = this.deleteSession.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.sortDates = this.sortDates.bind(this);
    this.hideErrToast = this.hideErrToast.bind(this);
    this.hideSuccToast = this.hideSuccToast.bind(this);
  }

  /**
    * Hides success toast
    */
  hideSuccToast() {
    this.setState({...this.state, showAPISuccessToast: false});
  }

  /**
    * Hides error toast
    */
  hideErrToast() {
    this.setState({...this.state, showAPIErrorToast: false});
  }

  close () {
    this.setState({...this.state,dataRecieved: false, 
      data: [],
      graph: "Form",
      showAPIErrorToast: false,
      showAPISuccessToast: false,
    })
    this.props.close();
  }

  async getGraphData() {
    // Make call to the BE to get the data for the graphs
    const path = "getSessionDatasets/" + JSON.parse(sessionStorage.getItem("data")).pid + '/' + this.props.data.id;
    const headers = { "x-access-token": localStorage.getItem("auth_token") };

    try {
      const getPGraphDataResponse = await createGetRequest(path, headers);
      this.setState({...this.state,dataRecieved: true, data: this.sortDates(getPGraphDataResponse)});
    } catch(error) {
      console.log(error);
    }
  }

  getDaysSinceStartDate() {
    let currentDate = new Date();
    let startDate = new Date(this.props.data.start_date);
    return(((currentDate.getTime()) - startDate.getTime()) / (1000 * 3600 * 24));
  }

  deleteSession() {
    axios.delete("/removeMonitoringSession", {
      headers: {
        "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
      },
      data: {
        title: "Remove Monitoring Session Request",
        info: {
          pid: JSON.parse(sessionStorage.getItem("data")).pid,
          session_id: this.props.data.id
        }
      }
    }).then((res) => {
      this.props.deleteSession(res.data.sessionId);
      this.setState({...this.state,
        showConfirmModal: false,
        showAPIErrorToast: false,
        showAPISuccessToast: true, 
      });
    }).catch((err) => {
      console.log(err);
      this.setState({
        ...this.state,
        showAPIErrorToast: true,
        showAPISuccessToast: false,
      });
    });
  }

  closeConfirmModal() {
    this.setState({...this.state,showConfirmModal: false})
  }

  formatForAcc(data) {
    let acc_data = [];
    for (var i=0; i<data.length; i++) {
      var set = data[i];
      var temp = {
        date: set.date,
        sagittal: 100 * set.acc_distribution[0],
        axial: 100 * set.acc_distribution[1],
        coronal: 100 * set.acc_distribution[2]
      };
      acc_data = [...acc_data, temp];
    }
    return acc_data;
  }

  setGraph(graph) {
    this.setState({...this.state,graph: graph});
  }

    /**
   * Sorts dates by most recent
   * @param {Array<string>} dates Array of dates 
   * @returns Sorted array of dates
   */
    sortDates(dates) {
      dates.sort(function (a, b) {
        if (a.date < b.date) {
          return -1;
        } else if (a.date === b.date) {
          return 0;
        } else {
          return 1;
        }
    });
     return dates;
    }

  render() {
    if (!this.props.visible) return null;
    if(!this.state.dataRecieved) {this.getGraphData()};
    return(
      <div className="fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
        <div className="bg-white h-5/6 w-3/5 overflow-auto overscroll-none px-6 pb-6 rounded">
          {/* -----------------------------HEADER BAR--------------------------------------- */}
          <div className="sticky top-0 z-40 flex justify-between bg-white border-b-2 h-12 p-2 -mx-6">
            <div className="w-3/5 flex justify-end">
              <h1 className="font-semibold text-center text-xl text-gray-700">
                View Session
              </h1>
            </div>
            <div className="w-1/4 flex justify-end">
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-lg hover:bg-red-400 "
                onClick={() => this.close()}>
                  X
              </button>
            </div>
          </div>
          <div className="h-full">
            <div className="flex h-5/6">
              <ComplianceBucket
                metrics={this.props.data.metrics}
                rules={this.props.data.rules}
                startDate={this.props.data.start_date}
                evaluation={this.props.data.evaluation}
                sets={this.props.data.sets}
                houseKeeping={this.props.data.housekeeping}
                graph={this.state.graph}
                setGraph={this.setGraph}
                collectionProgress={get_progress(this.props.data.sets.length, this.props.data.rules.min_threshold, this.props.data.housekeeping)}/>
              <div className="bg-gray-200 h-full w-2/3 rounded-xl px-3 mt-3 ml-6 overflow-auto">
                {this.state.graph === "Form" && this.state.dataRecieved ? 
                  <LineChart 
                    data={this.state.data} 
                    labels={FORM_OVER_TIME} 
                    name={FORM_GRAPH}
                    legendSide={'Top'}
                    showGranularData={this.granularDataWorkaround}/>
                : null }
                {this.state.graph === "Gait Velocity" ? 
                  <LineChart
                    data={this.state.data}
                    labels={GAIT_VELOCITY_GRAPH}
                    name={GAIT_VELOCITY_GRAPH_NAME}
                    legendSide={'Top'}
                    showGranularData={null}/>
                : null}
                
              </div>
            </div>
            <div className="flex w-full h-1/6">
              <div className="w-1/3">
              <div className="mt-6 h-2/5">
                <button 
                  className="inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                  onClick={() => this.setState({...this.state,showConfirmModal: true})}>
                    Delete Session
                </button>
              </div>
              </div>
              <div className="w-2/3 ml-6">
                <div className="mt-6 h-1/2">
                  {this.props.data.rules.min_threshold != 0 ? 
                    <div className='w-full'> 
                      <h1 className="font-semibold text-center text-xl text-gray-700 -mb-4">
                        {"Sets Collected  (" + this.props.data.sets.length + "/" + this.props.data.rules.min_threshold + ")"}
                      </h1>
                      <div>
                        {this.props.data.rules.min_threshold >= this.props.data.sets.length ?
                          <SetsCollectedProgressBar
                            key={"SetsCollectedBar"}
                            divisor={parseInt(this.props.data.rules.min_threshold)}
                            numerator={this.props.data.sets.length}
                            colorScheme={get_progress(this.props.data.sets.length, this.props.data.rules.min_threshold, this.props.data.housekeeping)}/> :
                          <SetsCollectedProgressBar
                            key={"SetsCollectedBar"}
                            divisor={this.props.data.rules.min_threshold}
                            numerator={this.props.data.rules.min_threshold}
                            colorScheme={get_progress(this.props.data.sets.length, this.props.data.rules.min_threshold, this.props.data.housekeeping)}/>
                        }
                      </div>
                    </div>
                  : null}
                  { this.props.data.rules.period > Math.round(this.getDaysSinceStartDate()) &&  this.props.data.rules.period != 0 ? 
                    <div> 
                      { Math.round(this.getDaysSinceStartDate()) <= 0 ? 
                        <h1 className="font-semibold text-center text-xl text-gray-700 -mb-4"> Session Not Yet Started </h1> :
                        <h1 className="font-semibold text-center text-xl text-gray-700 -mb-4"> {"Days  (" + Math.round(this.getDaysSinceStartDate()) + "/" + this.props.data.rules.period + ")"} </h1> 
                      }
                      <SessionCompletionProgressBar
                        key={"SessionCompletionBar"}
                        divisor={this.props.data.rules.period}
                        numerator={this.getDaysSinceStartDate()}
                        colorScheme={0}/>
                    </div>
                  :
                    <div className='w-full'> 
                      <h1 className="font-semibold text-center text-xl text-gray-700 -mb-4">
                        Session Complete
                      </h1>
                      <SessionCompletionProgressBar
                        key={"SessionCompletionBar"}
                        value={1}
                        colorScheme={0}/>
                    </div>
                  }
                </div>
              </div>
            </div>
            <ClickToConfirm
              visible={this.state.showConfirmModal}
              close={this.closeConfirmModal}
              submit={this.deleteSession} 
              data = {this.props.data}/>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 mr-10 mb-6">
          {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue deleting this session"} visible={this.state.showAPIErrorToast} hide={this.hideErrToast}/> : null}
          {this.state.showAPISuccessToast ? <SuccessToast message={"Session deleted successfully"} visible={this.state.showAPISuccessToast} hide={this.hideSuccToast}/> : null}
        </div>
      </div>
    )
  }
}