/**
 * Get the progress value for a session
 * @param {number} sets_collected Number of sets collected so far
 * @param {number} goal Number of sets to collect
 * @param {number} collection_progress Housekeeping progress
 * @returns 0 - Green - :), 1 - Yellow (under?), 2 - Red (under?), 3 - Yellow (over?), 4 - Red (over?)
 */
export function get_progress(sets_collected, goal, collection_progress) {
    const base = 100;

    // Calculate sets remaining
    let sets_remaining = goal - sets_collected;

    // Calculate adjustment factor
    let adjustment_factor = Math.floor(sets_remaining / 10);
    // Ensure that the adjustment factor is non-zero
    if (adjustment_factor == 0) adjustment_factor++;

    // Set ranges
    let over = [20, 24];
    let under = [20, 19];

    // Calcualate updated ranges with adjustment factor
    let calc_over = [];
    let calc_under = [];
    for (let i=0; i<over.length; i++) {
        calc_over[i] = over[i] / adjustment_factor;
        calc_under[i] = under[i] / adjustment_factor;
    }

    // Return value
    let ret = 0;

    // Check for under/over collection
    if (collection_progress <= base) {
        if (collection_progress >= (base - calc_over[0])) {
            ret = 0;    // Green (perfect or slightly under?)
        } else if ((collection_progress >= base - calc_over.reduce((a, b) => a + b, 0)) &&
                    collection_progress < (base - calc_over[0])) {
            ret = 1;    // Yellow (a warning under?)
        } else {
            ret = 2;    // Red (way under?)
        }
    } else {
        if (collection_progress <= (base + calc_under[0])) {
            ret = 0;    // Green (perfect or slightly over?)
        } else if ((collection_progress <= (base + calc_under.reduce((a, b) => a + b, 0))) &&
                    collection_progress > (base + calc_under[0])) {
            ret = 3;    // Yellow (a warning over?)
        } else {
            ret = 4;    // Red (way over?)
        }
    }

    return ret;
}
