import React, { Component } from "react";
import axios from 'axios';
import { AUTH_TOKEN_KEY } from "../../../resources/Vault";
import { createDeleteRequest } from '../../auth/utils/axios-utils';

export class ClickToConfirm extends Component{
  
  constructor(props) {
    super(props);
    this.state = {
      opacity: false,
    };
    this.onSubmit = this.onSubmit.bind(this)
    this.close = this.close.bind(this)
  }

  async onSubmit () {
    this.setState({...this.state, opacity: true});
    // Configure the request
    const path = "/removeMonitoringSession";
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const data = {
      title: "Remove Monitoring Session Request",
      info: {
        pid: JSON.parse(sessionStorage.getItem("data")).pid,
        session_id: this.props.data.id
      }
    };

    try {
      const removeMonitoringSessionResponse = createDeleteRequest(path, headers, data);
      this.setState({...this.state, opacity: false});
      this.props.submit(removeMonitoringSessionResponse);
    } catch(error) {
      this.setState({...this.state, opacity: false});
      console.log(error);
    }
  };

  close () {
    this.props.close();
  }

  render(){
    if (!this.props.visible) return null;
    return (
      <>
        <div className={"z-10 fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white p-4 rounded">
            <div className="flex justify-center">
              <h1 className="font-semibold text-center text-xl text-gray-700 mx-16">
                Confirm
              </h1>
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-xl hover:bg-red-400"
                onClick={() => this.close()}>
                X
              </button>
            </div>
            <button 
              className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
              onClick={this.onSubmit}>Confirm
            </button>
          </div>
        </div>
      </>
    )
  }
}
