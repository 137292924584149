import React, { Component } from "react";
import { ADD_ORG_USER_PATH, PERMISSIONS, ADD_ORG_USER_TITLE } from '../../../resources/Vault';
import { ErrorToast }  from '../../core/components/ErrorToast';
import { SuccessToast } from '../../core/components/SuccessToast';
import { createPostRequest } from '../../auth/utils/axios-utils';

export class AddUser extends Component{
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      error: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
      opacity: false,
    };
    this.onSubmit = this.onSubmit.bind(this)
    this.checkErrors = this.checkErrors.bind(this)
    this.handleKeypress = this.handleKeypress.bind(this)
    this.close = this.close.bind(this)
    this.hideErrToast = this.hideErrToast.bind(this);
    this.hideSuccToast = this.hideSuccToast.bind(this);
  };

  /**
   * Function which checks for errors and then closes the modal
   * If we have no errors then submit, else show error message
   */
  checkErrors () {
    if(this.state.email == ""){
      this.setState({...this.state,error:true})
    } else {
      this.onSubmit()
    }
  };

  /**
   * 
   * @param {*} e 
   * Function which detects the clicking of the enter key
   * When enter is clicked, then we call the checkErrors method which submits the modal
   */
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.checkErrors();
    }
  };

  /**
    * Hides success toast
    */
    hideSuccToast() {
    this.setState({...this.state, showAPISuccessToast: false});
  }

  /**
   * Hides error toast
   */
  hideErrToast() {
    this.setState({...this.state, showAPIErrorToast: false});
  }

  /**
   * Function which submits the modals
   * Call the backend to add a user and check to make sure the request completes
   * successfully before closing
   */
  async onSubmit () {
    this.setState({...this.state, opacity: true});

    // Configure the add user request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const body = {
      title: ADD_ORG_USER_TITLE,
      info: {email: this.state.email, permissions: PERMISSIONS.USER}
    };

    try {
      const addUserResponse = await createPostRequest(ADD_ORG_USER_PATH, headers, body);
      this.setState({...this.state, 
        opacity: false, 
        showAPISuccessToast: true
      });
      this.props.handleOnClose(addUserResponse);
    } catch(error) {
      this.setState({...this.state, opacity: false});
      console.log(error);
      this.setState({
        ...this.state,
        showAPIErrorToast: true,
        showAPISuccessToast: false,
      })
    }

    this.setState({
      ...this.state,
      email:"",
      error: false
    })
  };

  /**
   * Function which closes the modal window
   * After closing the window, we reset state to default
   */
  close () {
    this.props.handleOnExit();
    this.setState({
      email:"",
      error: false,
      showAPIErrorToast:false,
      showAPISuccessToast: false,
    })
  }

  render(){
    if (!this.props.visible) return null;
    return (
      <>
        <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white p-4 rounded">
            <div className="flex justify-center">
              <h1 
                className="font-semibold text-center text-xl text-gray-700 mx-16"
                >
                Add User
              </h1>
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-xl hover:bg-red-400"
                onClick={() => this.close()}>
                X
              </button>
            </div>

            <div className="relative">
              <label className="font-medium text-gray-900">
                User Email
              </label>
              <input
                onChange={event => this.setState({...this.state,email:event.target.value})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Email"
              />
            </div>

            {this.state.error ? <label className="font-semibold text-center text-red-400">*Fields Cannot Be Blank*</label> : null}
            <button 
              className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
              onClick={() => this.checkErrors()}>
                Submit
            </button>
          </div>
          <div className="absolute bottom-0 right-0 mr-10 mb-6">
            {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue adding this user"} visible={this.state.showAPIErrorToast} hide={this.hideErrToast}/> : null}
            {this.state.showAPISuccessToast ? <SuccessToast message={"User has been added"} visible={this.state.showAPISuccessToast} hide={this.hideSuccToast}/> : null}
          </div>
        </div>
      </>
    )
  }
}