import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';

/**
 * Used to set the environment status.
 * true = local env, false, production env
 * @type {boolean}
 */
const LOCAL = false;

axios.defaults.baseURL = 
    LOCAL ? 'http://localhost:3001' : '/api';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App local={LOCAL}/>);
