import React, {  Component } from 'react';
import { FORGOT_PASS_PAGE_TITLE, FORGOT_PASS_PATH } from '../../../resources/Vault';
import { ErrorToast } from '../../core/components/ErrorToast';
import { SuccessToast } from '../../core/components/SuccessToast';
import { createPostRequest } from '../utils/axios-utils';

class ForgotPassword extends Component {
    constructor () {
        super();

        this.state = {
            email: "",
            error: false,
            showSuccToast: false,
            showErrToast: false
        }

        this.handleKeypress = this.handleKeypress.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.hideErrToast = this.hideErrToast.bind(this);
        this.hideSuccToast = this.hideSuccToast.bind(this);
    }

    /**
     * Checks for enter key being pressed
     * @param {event} e Key press event
     */
    handleKeypress(e) {
        if (e.charCode === 13) {
        this.checkErrors();
        }
    };

    /**
     * Check for empty input field
     */
    checkErrors() {
        if (this.state.email == "") {
            this.setState({...this.state, error: true});
        } else {
            this.onSubmit();
        }
    }

    /**
     * Submit user input to backend
     */
    async onSubmit() {
        if (!this.state.error) {
            const body = {
                email: this.state.email
            };
            
            try {
                await createPostRequest(FORGOT_PASS_PATH, {}, body);
                this.setState({...this.state, showSuccToast: true});
            }
            catch(err) {
                console.log(err);
                this.setState({...this.state, showErrToast: true});
            }
        }
    }

    /**
     * Hides success toast
     */
    hideSuccToast() {
        this.setState({...this.state, showSuccToast: false});
    }

    /**
     * Hides error toast
     */
    hideErrToast() {
        this.setState({...this.state, showErrToast: false});
    }

    /**
     * Component lifecycle method, runs when component mounts
     */
    componentDidMount() {
        document.title = FORGOT_PASS_PAGE_TITLE;
    }

    render () {
        return (
            <>
                <div className="flex w-1/2 flex-row justify-center">
                    <div className="w-full px-10 py-16 ml-auto mr-auto bg-white xl:py-32 md:w-3/5 lg:w-4/5 xl:w-4/5">
                        <div className="-ml-3">
                            <p className="mb-4 text-2xl mt-10 font-extrabold leading-snug tracking-tight text-left text-primary md:text-4xl">
                                Forgot Password?
                            </p>
                        </div>
                        <div className="relative">
                            <label className="font-medium text-gray-900">Enter your email to reset your password.</label>
                            <input
                                onChange={event => this.setState({...this.state,email: event.target.value, error:false})}
                                onKeyPress={this.handleKeypress}
                                type="text"
                                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                                placeholder="Email"
                            />
                        <label className="text-red-500">{this.state.error ? "* Email Field Cannot Be Blank" : ""}</label>
                        </div>
                        <div className="mt-8 space-y-10" onClick={event => this.checkErrors()}>
                            <div className="grid grid-cols-1 gap-4">
                            <div className="flex items-center justify-center w-full pt-3 pb-3 text-white bg-blue-500 rounded-md cursor-pointer hover:bg-blue-600">
                                <p>Reset Password</p>
                            </div>
                            </div>
                        </div>
                        <div className="absolute bottom-0 right-0 mr-10 mb-6">
                            {this.state.showErrToast ? <ErrorToast message={"We encountered an error. Please try again"} visible={this.state.showErrToast} hide={this.hideErrToast}/> : null}
                            {this.state.showSuccToast ? <SuccessToast message={"Password reset email sent"} visible={this.state.showSuccToast} hide={this.hideSuccToast}/> : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ForgotPassword;
