import React, { Component } from 'react';
import Medication from '../components/Medication'
import RemovePatient from '../modals/RemovePatient';
import PatientDataWindow from '../components/patientDataWindow';
import { withRouter } from 'react-router';
import { SuccessToast } from '../../core/components/SuccessToast';
import { AddMedication } from '../modals/AddMedication';
import { INFO_KEY, LOGIN_PAGE_PATH, PERMISSIONS, GET_PATIENT_PATH, PORTAL_PAGE_PATH } from '../../../resources/Vault';
import { ErrorToast } from '../../core/components/ErrorToast';
import { createGetRequest } from '../../auth/utils/axios-utils';

/**
 * Patient data display page, displays basic patient information
 * as well as Balance data products
 */
class PatientData extends Component {
    
    constructor(props) {
      super(props);
      /**
       * PatientData component state
       * @type {Object}
       * @property {string} pid Patient ID
       * @property {Array<Object>} med_props Medication info to render
       * @property {Object} patientData Patient information
       * @property {Object} info User information from local storage
       * @property {boolean} showModal Remove patient modal visible?
       * @property {boolean} showModalMedication Add medication modal visible?
       * @property {boolean} showRemovePatientSuccessToast Success toast visible?
       * @property {boolean} addMedicationErrorToast Error toast visible?
       */
      this.state = {
        pid: this.props.match.params.pid,
        med_props: [],
        patientData: {},
        info: JSON.parse(localStorage.getItem(INFO_KEY)),
        showModal: false,
        showModalMedication: false,
        showRemovePatientSuccessToast: false,
        addMedicationErrorToast: false,
      };
      this.handleOnClose = this.handleOnClose.bind(this)
      this.handleOnExitRemovePatient = this.handleOnExitRemovePatient.bind(this);
      this.handleOnExitAddMedication = this.handleOnExitAddMedication.bind(this);
      this.calcHeight = this.calcHeight.bind(this);
      this.updateMeds = this.updateMeds.bind(this);
      this.hideErrToast = this.hideErrToast.bind(this);
      this.hideSuccToast = this.hideSuccToast.bind(this);
    }

    /**
      * Hides success toast
      */
    hideSuccToast() {
      this.setState({...this.state, showRemovePatientSuccessToast: false});
    }

    /**
      * Hides error toast
      */
    hideErrToast() {
      this.setState({...this.state, addMedicationErrorToast: false});
    }

    /**
     * Closes remove patient modal
     */
    handleOnExitRemovePatient () {
      this.setState({...this.state,showModal: false});
    };

    /**
     * Closes add medication modal
     */
    handleOnExitAddMedication () {
      this.setState({...this.state,showModalMedication: false});
    };

    /**
     * Closes remove patient modal window with submission
     */
    handleOnClose () {
      this.setState({...this.state,showModal: false, showRemovePatientSuccessToast: true});
    };

    updateMeds(med) {
      let updatedMedicationList = this.state.med_props;
      updatedMedicationList.push(med);
      this.setState({
        ...this.state, 
          med_props: updatedMedicationList
      });
    }

    /**
     * Component lifecycle method, runs before mount
     * Checks for authentication, formats any medication
     */
    async componentDidMount() {
      document.title = "Patient Data | Patient Management Portal";
      // Configure the API requests
      const headers = { "x-access-token": localStorage.getItem("auth_token") };

      try {
        const getPatientResponse = await createGetRequest(GET_PATIENT_PATH + this.state.pid, headers);
        this.setState({...this.state, patientData: getPatientResponse});

        // If the patient has medication data then add it 
        if(getPatientResponse.medication) {
          var keys = [];
          Object.entries(getPatientResponse.medication).map(([key,value]) => keys.push(key));
          keys.forEach((key) => {
            var temp = {
              name: key,
              start: getPatientResponse.medication[key].startDate,
              end: getPatientResponse.medication[key].endDate
            };
            this.state.med_props.push(temp);    
          });
        }
      } catch (error) {
        console.log(error);
        this.props.history.push({pathname: PORTAL_PAGE_PATH});
      }
    }

    /**
     * Calculate height from inches
     * @param {int} inches 
     * @returns Formatted height string
     */
    calcHeight(inches) {
      let feet = Math.floor(inches / 12);
      let inch = Math.floor(inches % 12);
      return feet + " ft. " + inch + " in.";
    }

    render() {
      if (this.state.info.permission == PERMISSIONS.OWNER || this.state.info.permission == PERMISSIONS.ADMIN || this.state.info.permission == PERMISSIONS.USER) {
        return (
        <div className="patientDataContainer">
          <label className="flex justify-center font-medium font-sans text-6xl text-primary text-base font-medium">{!this.state.info ? "Loading" : this.state.info.org}</label>
          <div className="flex h-screen w-full bg-white pt-6 pb-16">
            <div className="h-full ml-20 pb-8 w-1/5 min-w-min">
              <div className="items-center justify-center overflow-auto px-4 max-h-full bg-gray-200 rounded-3xl">
                <div className="flex justify-center">
                  <div className="p-4 space-y-3">
                  {this.state.info.permission == PERMISSIONS.OWNER|| this.state.info.permission == PERMISSIONS.ADMIN ? <button
                    type="button"
                    className=" inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                    onClick={() => this.setState({...this.state,showModal: true})}>
                      Remove Patient
                    </button> : null}
                    {this.state.info.permission == PERMISSIONS.OWNER|| this.state.info.permission == PERMISSIONS.ADMIN ? <button
                    type="button"
                    className="inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                    onClick={() => this.setState({...this.state,showModalMedication: true})}>
                      Add Medication
                    </button> : null}
                    
                    {
                      this.state.patientData.firstName !== "" && this.state.patientData.lastName !== "" ?
                        <div className="hover:bg-gray-300 border-4 border-black rounded-3xl w-full p-3">
                          <label className="flex justify-center font-normal text-gray-900">Patient Name:</label>
                          <label className="flex justify-center font-semibold text-gray-900">{this.state.patientData.firstName} {this.state.patientData.lastName}</label>
                        </div>
                      : 
                        null
                    }
                    {
                      this.state.patientData.dob !== "" ?
                        <div className="hover:bg-gray-300 border-4 border-black rounded-3xl w-full p-3">
                          <label className="flex justify-center font-normal text-gray-900">Date of Birth:</label>
                          <label className="flex justify-center font-semibold text-gray-900">{this.state.patientData.dob}</label>
                        </div>
                      :
                        null
                    }
                    {
                      this.state.patientData.height !== "" ?
                        <div className="hover:bg-gray-300 border-4 border-black rounded-3xl w-full p-3">
                          <label className="flex justify-center font-normal text-gray-900">Height:</label>
                          <label className="flex justify-center font-semibold text-gray-900">{this.calcHeight(this.state.patientData.height)}</label>
                        </div>
                      :
                        null
                    }
                    {
                      this.state.patientData.weight !== "" ?
                        <div className="hover:bg-gray-300 border-4 border-black rounded-3xl w-full p-3">
                          <label className="flex justify-center font-normal text-gray-900">Weight:</label>
                          <label className="flex justify-center font-semibold text-gray-900">{this.state.patientData.weight} lbs</label>
                        </div>
                      :
                        null
                    }
                    {
                      this.state.patientData.doctor !== "" ?
                        <div className="hover:bg-gray-300 border-4 border-black rounded-3xl w-full p-3">
                          <label className="flex justify-center font-normal text-gray-900">Doctor:</label>
                          <label className="flex justify-center font-semibold text-gray-900">{this.state.patientData.doctor}</label>
                        </div>
                      :
                        null
                    }
                    {
                      this.state.patientData.sex !== "" ?
                        <div className="hover:bg-gray-300 border-4 border-black rounded-3xl w-full p-3">
                          <label className="flex justify-center font-normal text-gray-900">Sex:</label>
                          <label className="flex justify-center font-semibold text-gray-900">{this.state.patientData.sex}</label>
                        </div>
                      :
                        null
                    }
                    
                    {!this.state.med_props.length >= 1 ? null : <label className="flex justify-center font-bold text-gray-900">Medications:</label>}
                    {this.state.med_props.map(block => Medication(block))}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-4/5 h-full pb-8 border-2 rounded-xl mx-5">
              <PatientDataWindow pid={this.state.pid}/>
            </div>
            <RemovePatient
              visible={this.state.showModal}
              handleOnClose = {this.handleOnClose}
              pid = {this.state.patientData.pid}
              handleOnExitRemovePatient = {this.handleOnExitRemovePatient}
            />
            <AddMedication
              visible={this.state.showModalMedication}
              addMedication = {this.addPatient}
              handleOnExitAddMedication = {this.handleOnExitAddMedication}
              updateMeds = {this.updateMeds}
            />
            <div className="absolute bottom-0 right-0 mr-10 mb-6">
              {this.state.addMedicationErrorToast ? <ErrorToast message={"Unable to add medication"} visible={this.state.addMedicationErrorToast} hide={this.hideErrToast}/> : null}
              {this.state.showRemovePatientSuccessToast ? <SuccessToast message={"Patient Was Removed Successfully"} visible={this.state.showRemovePatientSuccessToast} hide={this.hideSuccToast}/> : null}
            </div>
          </div>
        </div>
        )
      } else {
        this.props.history.push({pathname: LOGIN_PAGE_PATH});
      }
      }
  };

export default withRouter(PatientData);
