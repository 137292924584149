import React, { Component } from 'react';

export class PopOverMenu extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showMenu: this.props.showMenu,
      }
      this.wrapperRef = React.createRef();
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }
  
    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  
    // Closes the menu if you click outside of it
    handleClickOutside(event) {
      if(this.props.showMenu) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.handleOnClose()
        }
      }
    }
  
    render() {
      if(!this.props.showMenu) return null
      return(
        <div ref={this.wrapperRef}>
            <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-300 shadow-lg rounded-lg">
              <ul className="py-2">
                <li 
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => this.props.showEditPermissionsModal()}>
                    Edit Role
                </li>
                <li 
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => this.props.showRemoveUserModal()}>
                    Delete User
                </li>
              </ul>
            </div>
        </div>
      );
    }
  }
  