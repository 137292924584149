import React, { Component } from "react";
import { withRouter } from 'react-router';
import  UserSimple  from './UserSimple';
import { AddUser } from '../modals/AddUser';
import { EditOrg } from '../modals/EditOrg';
import { GET_ORG_USER_PATH } from '../../../resources/Vault';
import { DASHBOARD_TEST_DATA } from '../../../resources/TestData';
import { SuccessToast } from '../../core/components/SuccessToast';
import { ErrorToast } from '../../core/components/ErrorToast';
import { getResultList } from '../../core/utils/get-search-results';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/fontawesome-free-solid";
import { cardio } from 'ldrs';
import { createGetRequest } from '../../auth/utils/axios-utils';
/**
 * Organization management component
 */
class Management extends Component {
  constructor() {
    super();
    /**
     * Management component state
     * @type {Object}
     * @property {boolean} editOrg
     * @property {Array<Object>} users List of users
     * @property {Object} info User info from local storage
     * @property {boolean} showAddUserModal Add user modal showing
     * @property {boolean} showEditOrgModal Edit org modal showing
     */
    this.state = {
      editOrg: false,
      users: [],
      info: JSON.parse(localStorage.getItem('info')),
      showAddUserModal: false,
      showEditOrgModal: false,
      originalUserSet: [],
    };
    this.handleOnCloseAddUser = this.handleOnCloseAddUser.bind(this);
    this.handleOnCloseEditOrg = this.handleOnCloseEditOrg.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.handleOnExit = this.handleOnExit.bind(this);
    this.updatePermission = this.updatePermission.bind(this);
    this.getSearchResultList = this.getSearchResultList.bind(this);
    // Register the loading animation
    cardio.register();
  }

  /**
   * Adds user to the list and closes the modal
   * @param {Object} user User object
   */
  handleOnCloseAddUser (user) { 
    this.setState({
      ...this.state,
      showAddUserModal: false,
      users: [...this.state.users, user.data],
      originalUserSet: [...this.state.originalUserSet, user.data]
    });
  };

  /**
   * Update org name and close modal
   * @param {string} orgName Organization name
   */
  handleOnCloseEditOrg (orgName) {
    this.setState({
      ...this.state,
      showEditOrgModal: false,
      info: {
        ...this.state.info,
        orgName: orgName
      }
    });
    localStorage.setItem("info", JSON.stringify({
      ...this.state.info,
      orgName: orgName
    }));
  }

  /**
   * Removes user from the list by email
   * @param {string} email Email
   */
  removeUser(email) {
    let temp = this.state.users;
    for(let i=0; i < temp.length; i++) {
      if (temp[i].email === email) {
        delete temp[i];
      }
    }
    this.setState({
      ...this.state,
      showModal: false,
      users: temp,
      originalUserSet: temp
    });
  };

  /**
   * Update user permissions by email
   * @param {string} permission Permission level
   * @param {string} email Email
   */
  updatePermission(permission, email) {
    let temp = this.state.users;
    for(let i=0; i < temp.length; i++) {
      if (temp[i].email === email) {
        temp[i].permission = permission;
      }
    }
    this.setState({
      ...this.state,
      users: temp,
      originalUserSet: temp
    });
  };

  /**
   * Closes modals
   */
  handleOnExit () {
    this.setState({...this.state,
      showAddUserModal: false,
      showEditOrgModal: false});
  };

  /**
   * Gets users from backend API
   */
  async componentDidMount() {
    // Configure get users API
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    try {
      const getUsersResponse = await createGetRequest(GET_ORG_USER_PATH, headers);
      this.setState({...this.state, users: getUsersResponse, originalUserSet: getUsersResponse});
    } catch(error) {
      console.log(error);
    }
  }

  getSearchResultList(searchTerm) {
    this.setState({...this.state, users: getResultList(this.state.originalUserSet, searchTerm)});
  }

  render() {
    return(
      <>
        <div className="flex flex-col mt-4 mr-4">
          <div className="bg-[#E5E4E2] rounded">
            <div className="flex flex-row justify-center mt-4">
              <label className="text-3xl font-bold">
                {this.state.info ? this.state.info.orgName : "Loading..."}
              </label>
              <button 
                className="ml-5 text-2xl"
                onClick={() => this.setState({...this.state,showEditOrgModal: true})}>
                  <FontAwesomeIcon icon={Icons.faPencilAlt} />
              </button>
            </div>

            {/* THIS IS THE EXPLANATION / SEARCH / ADD USER SECTION */}
            <div className="w-full flex flex-row mt-8">
              <div className="w-1/2 flex flex-col ml-4">
                {/* User Management and info block */}
                <label className="text-2xl font-bold">User Management</label>
                <label className="text-md">Add a New User or Update User Permissions</label>
              </div>
              <div className="w-1/2 flex flex-row-reverse">
                {/* Add user button */}
                { this.state.info.permission != 'user' ? 
                  <button
                    className="w-2/5 h-full mx-4 rounded-md bg-primary hover:bg-accent text-white"
                    onClick={() => this.setState({...this.state,showAddUserModal: true})}>
                    Add User
                  </button>
                  : null }
                {/* Search Bar */}
                <input
                  type="Search"
                  id="Search"
                  className=" text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder="Search"
                  autoComplete="off"
                  readOnly
                  onChange={event => this.getSearchResultList(event.target.value)} />
              </div>
            </div>

            {/* This is the 'outline' section, shows the names of all of the fields below */}
            <div className="mt-7  border-b-4 border-[#D3D3D3] shadow-lg rounded">
              <div className="w-full flex flex-row border-b-4">
                <div className="w-1/3 flex flex-col ml-4">
                  <label className="text-lg">Name</label>
                </div>
                <div className="w-1/3">
                  <label className="text-lg">Role</label>
                </div>
                <div className="w-1/3">
                  <label className="text-lg">Email</label>
                </div>
              </div>

              
            </div>

          </div>
          <div className="flex flex-col divide-y-2">
            {this.state.users.length == 0 ? 
              <div className="w-full flex justify-center mt-10">
                <l-cardio size="80" stroke="6" speed="2" color="black" />
              </div>
            : 
              this.state.users.map(block => <UserSimple 
                key={block.email}
                firstName={block.firstName} 
                lastName={block.lastName} 
                permission={block.permission}
                email={block.email}
                removeUser={this.removeUser}
                updatePermission={this.updatePermission}/>)}
          </div>
        </div>
        <AddUser
          visible={this.state.showAddUserModal}
          handleOnClose = {this.handleOnCloseAddUser}
          handleOnExit = {this.handleOnExit}
        />
        <EditOrg 
          orgName={this.state.info.orgName}
          visible = {this.state.showEditOrgModal}
          handleOnClose = {this.handleOnCloseEditOrg}
          handleOnExit = {this.handleOnExit}
        />
      </>
    )
  }
}
export default withRouter(Management);
