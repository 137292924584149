import bcrypt from "bcryptjs";
import React, { Component } from "react";
import { UPDATE_USER_PASSWORD_PATH, UPDATE_USER_PASSWORD_TITLE, AUTH_TOKEN_KEY } from '../../../resources/Vault';
import { ErrorToast }  from '../../core/components/ErrorToast';
import { SuccessToast } from '../../core/components/SuccessToast';
import { createPutRequest } from '../../auth/utils/axios-utils';

export class ChangePassword extends Component{
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      password1: "",
      password2: "",
      error: false,
      oldFieldEmpty: false,
      newFieldEmpty: false,
      confirmFieldEmpty: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
      opacity: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.close = this.close.bind(this);
    this.hideErrToast = this.hideErrToast.bind(this);
    this.hideSuccToast = this.hideSuccToast.bind(this);
  }

  /**
   * Function which checks for errors and then closes the modal
   * If we have no errors then submit, else show error message
   */
  checkErrors () {
    this.setState({...this.state,
      oldFieldEmpty: this.state.oldPassword == "",
      newFieldEmpty: this.state.password1 == "",
      confirmFieldEmpty: this.state.password2 == "",
    })
    let passwordMatch = this.state.password1 === this.state.password2;
    let passwordLength = this.state.password1.length >= 10 && this.state.password1.length <= 32;
    let hasUpper = this.state.password1.toUpperCase() !== this.state.password1;
    let hasLower = this.state.password1.toLowerCase() !== this.state.password1;
    let specialCharacters = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);
    let hasSpecial = specialCharacters.test(this.state.password1);
    let passwordError = true;
    if (passwordMatch && passwordLength && hasUpper && hasLower && hasSpecial) {
      passwordError = false;
    }
    this.onSubmit(passwordError);
  }

  /**
   * 
   * @param {*} e 
   * Function which detects the clicking of the enter key
   * When enter is clicked, then we call the checkErrors method which submits the modal
   */
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.checkErrors();
    }
  };

  /**
    * Hides success toast
    */
  hideSuccToast() {
    this.setState({...this.state, showAPISuccessToast: false});
  }

  /**
   * Hides error toast
   */
  hideErrToast() {
    this.setState({...this.state, showAPIErrorToast: false});
  }

  /**
   * @param {boolean} passErr
   * Function which submits the modals
   * We call the handle on close method of the parent component and close the modal
   */
  async onSubmit (passErr) {
    if(passErr) { return }
    this.setState({...this.state, opacity: true});
    // Configure the change password request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const body = {
      title: UPDATE_USER_PASSWORD_TITLE,
      info: { 
        password: this.state.oldPassword,
        new_hash: bcrypt.hashSync(this.state.password1)
      }
    };
    try {
      const updatePasswordResponse = await createPutRequest(UPDATE_USER_PASSWORD_PATH, headers, body);
      this.setState({...this.state, opacity: false});
      this.setState({
        ...this.state,
        showAPIErrorToast: false,
        showAPISuccessToast: true,
      });
      this.props.handleOnClose(this.state)
      this.close();
    } catch(error) {
      this.setState({
        ...this.state,
        showAPISuccessToast: false,
        showAPIErrorToast: true,
        opacity: false
      });
      console.log(error);
    }
  };

  /**
   * Function which closes the modal window
   * After closing the window, we reset state to default
   */
  close () {
    this.props.handleOnClose();
    this.setState({
      oldPassword: "",
      password1: "",
      password2: "",
      error: false,
      oldFieldEmpty: false,
      newFieldEmpty: false,
      confirmFieldEmpty: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
    });
  };

  render(){
    if (!this.props.visible) return null;
    return (
      <>
        <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white p-4 rounded">
          <div className="flex justify-center">
              <h1 
                className="font-semibold text-center text-xl text-gray-700 mx-16"
                >
                Change Password
              </h1>
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-xl hover:bg-red-400"
                onClick={() => this.close()}>
                X
              </button>
            </div>
            <div className="relative">
              <label className="font-medium text-gray-900">
                Old Password
              </label>
              {!this.state.oldFieldEmpty ? 
                <input
                  type="password"
                  onChange={event => this.setState({...this.state,oldPassword: event.target.value})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder=""
                />
              :
                <input
                  type="password"
                  onChange={event => this.setState({...this.state,oldPassword: event.target.value, oldFieldEmpty: false})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder=""
                />
              }
            </div>

            <div className="relative">
              <label className="font-medium text-gray-900">
                New Password
              </label>
              {!this.state.newFieldEmpty ? 
                <input
                  type="password"
                  onChange={event => this.setState({...this.state,password1: event.target.value})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder=""
                />
              :
                <input
                  type="password"
                  onChange={event => this.setState({...this.state,password1: event.target.value, newFieldEmpty: false,})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder=""
                />
              }
            </div>

            <div className="relative">
              <label className="font-medium text-gray-900">
                Confirm New Password
              </label>
              {!this.state.confirmFieldEmpty ? 
                <input
                  type="password"
                  onChange={event => this.setState({...this.state,password2: event.target.value})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder=""
                />
              :
                <input
                  type="password"
                  onChange={event => this.setState({...this.state,password2: event.target.value, confirmFieldEmpty: false})}
                  onKeyPress={this.handleKeypress}
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder=""
                />
              }
            </div>
            {!(this.state.password1 == this.state.password2) ? <label className="font-semibold text-center text-red-400">* Passwords do not match</label> : null}
            <button 
            className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
            onClick={this.checkErrors}>Change Password</button>
          </div>
          <div className="absolute bottom-0 right-0 mr-10 mb-6">
            {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue changing your password"} visible={this.state.showAPIErrorToast} hide={this.hideErrToast}/> : null}
            {this.state.showAPISuccessToast ? <SuccessToast message={"Password changed successfully"} visible={this.state.showAPISuccessToast} hide={this.hideSuccToast}/> : null}
          </div>
        </div>
      </>
    )
  }
}