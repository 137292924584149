import React, { Component } from 'react';

/**
 * Component to manage the active tab 
 */
class Tab extends Component {
  constructor(props) {
    /**
     * Tab component state
     * @type object
     * @property {string} tabName Name of tab
     * @property {number} tabId Id number of tab
     * @property {boolean} tabIsActive Tab is active? 
     */
    super(props);
    this.state = {
      tabName: this.props.tabName,
      tabID: this.props.tabID,
      tabIsActive: this.props.tabIsActive
    }
    this.setTabActive = this.setTabActive.bind(this)
  }

  /**
   * Sets the state of an inactive tab to active when it is clicked
   */
  setTabActive() {
    this.setState({
      ...this.state,
      tabIsActive: true
    });
    this.props.activateTab(this.state.tabID);
  }

  render() {
    return (
      <div className="inline-flex w-1/3">
        {this.props.tabIsActive
          ?
            <button 
              className='border-2 border-primary px-8 py-2 w-full text-base font-medium leading-6 text-white 
                whitespace-no-wrap transition duration-150 ease-in-out bg-primary text-background'>
                {this.state.tabName}
            </button> 
          :
            <button 
              className='border-2 border-primary px-8 py-2 w-full text-base font-medium leading-6 text-gray-600 
                whitespace-no-wrap transition duration-150 ease-in-out hover:text-background hover:bg-accent'
                onClick={() =>  this.setTabActive()}>
                {this.state.tabName}
            </button>
        }
      </div>
    )
  }   
}

export default Tab;