import {React, Component} from 'react';
import {removeSession} from '../utils/removeSession';
import { SessionBlock } from './SessionBlock';
import { SessionCreation } from '../modals/SessionCreation';
import { DATA_KEY, AUTH_TOKEN_KEY } from '../../../resources/Vault';
import { getMonitoringSessions } from '../../../resources/url_construction';
import { SessionViewModal } from '../modals/SessionViewModal';
import { createGetRequest } from '../../auth/utils/axios-utils';


class AggregateWindow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expandList: true,
      sessionModal: false,
      showSessionViewModal: false,
      sessionViewModalData: {},
      sessions: []
    };
    this.renderList = this.renderList.bind(this);
    this.handleModalExit = this.handleModalExit.bind(this);
    this.handleNewSession = this.handleNewSession.bind(this);
    this.showSessionViewModal = this.showSessionViewModal.bind(this);
    this.closeSessionViewModal = this.closeSessionViewModal.bind(this);
    this.handleRemoveSession = this.handleRemoveSession.bind(this);
  }

  async componentDidMount() {
    // Configure the API request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const path = getMonitoringSessions(this.props.pid);
    try {
      const getMonitoringSessionsResponse = await createGetRequest(path, headers);
      this.setState({...this.state, sessions: getMonitoringSessionsResponse});
    } catch(error) {
      console.log(error);
    }
  }

  /**
   * 
   * @param {Object} data The data that makes up a session that is displayed in the modal
   * This function opens the session view modal 
   */
  showSessionViewModal(data) {
    this.setState({...this.state,showSessionViewModal: true, sessionViewModalData: data});
  }
  /**
   * This function closes the session view modal
   */
  closeSessionViewModal() {
    this.setState({...this.state,showSessionViewModal: false, sessionViewModalData: {}});
  }

  renderList(){
    const renderedItems = [];
    if (this.state.expandList) {
      for (const key in this.props.data.balance_dates) {
        const value = this.props.data.balance_dates[key];
        renderedItems.push(
          <li key={key}>
            {key}: {value}
          </li>
        );
      }
    }
    return renderedItems;
  };

  /**
   * Closes the session creation window
   */
  handleModalExit() {
    this.setState({...this.state,
      sessionModal: false
    });
  };

  /**
   * @param {String} sessionId : The session id that is being removed
   * Removes a session and closes the showSession modal
   */
  handleRemoveSession(sessionId) {
    let removedSessionArray = removeSession(this.state.sessions, sessionId);
    this.setState({...this.state,
      sessions: removedSessionArray,
      showSessionViewModal: false
    });
  };

  /**
   * Adds a new session object to the session list
   * @param {Object} session 
   */
  handleNewSession(session) {
    this.setState({...this.state,
      sessionModal: false,
      sessions: [...this.state.sessions, session]
    });
  };

  render() {
    return(
      <>
      {this.props.data === '' ? "Loading..." : 
        <div className="max-h-full h-screen w-full h-full rounded-3xl overflow-auto">
          <div className="flex flex-row justify-center space-x-10 w-full h-3/4 rounded-3xl mt-5">
            <div className="h-5/6 w-1/4 bg-gray-200 rounded-3xl px-5 pt-3 overflow-auto">
              <label className= "text-xl font-bold pt-3 flex justify-center items-center"> 
                Patient Monitoring
              </label>
              <button
                type="button"
                className=" inline-flex items-center justify-center px-8 py-2 mt-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                onClick={() => this.setState({...this.state,sessionModal: true})}>
                  Add Monitoring Session
              </button> 
              {this.state.sessions.map(session => <SessionBlock {...session}  key={session.id} showSessionViewModal={this.showSessionViewModal}/>)}
            </div>
            <div className= "h-5/6 w-3/4 space-y-5 flex flex-col">
              <div className="h-full bg-gray-200 rounded-3xl px-5 pt-5 space-y-4">
                <label className="font-bold flex justify-center items-end text-2xl">
                  Aggregate Data
                </label>
              <div className="flex h-5/6 flex-row">
                <div className = "bg-white rounded-3xl w-1/3 h-full p-5 overflow-auto">
                  <label className="flex justify-center font-bold text-gray-900 py-3">
                    <div
                      className={`cursor-pointer transition-transform duration-300 ${
                        this.state.expandList ? 'transform rotate-90' : ''
                      }`}
                      onClick={() => this.setState({expandList: !this.state.expandList})}
                    >
                      &#9654;
                    </div>
                    Total Observations: {this.props.data.count}
                  </label>
                  {this.state.expandList && (
                    <ul className="hidden-list font-bold space-y-4 text-base text-center">
                      {this.renderList()}
                    </ul>
                  )}
                </div>
                <div className="px-12">
                  <label className="flex font-bold text-gray-900">
                    Average Form Score: {Math.round(this.props.data.form.avg * 1000) / 1000}
                  </label>
                  <label className="flex font-bold text-gray-900">
                    Average Gait Velocity: {Math.round(this.props.data.gait_velocity.avg * 1000) / 1000} m/s
                  </label>
                  <label className="flex font-bold text-gray-900">
                    Gait Classification: 
                    {this.props.data.gait.classification.Normal >= this.props.data.gait.classification.Hemiplegic 
                    ? " Normal" : " Hemiplegic"}
                  </label>
                  <label className="flex font-bold text-gray-900">
                    Confidence Level: {Math.round(this.props.data.gait.conf_percs[1] * 100) / 100} %
                  </label>
                </div>
              </div>
            </div>
            </div>
          </div>
          <SessionCreation
            visible={this.state.sessionModal}
            exit={this.handleModalExit}
            submit={this.handleNewSession}/>
          <SessionViewModal
           visible={this.state.showSessionViewModal}
           close={this.closeSessionViewModal}
           data={this.state.sessionViewModalData}
           deleteSession={this.handleRemoveSession}/>
        </div>
      }
      </>
    );
    }
};

export default AggregateWindow;
