import React, { Component } from "react";

export class RuleSimple extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render() {
    if(this.props[1].showGraph === this.props.activeGraph) {
      return (
        <div onClick={() => this.props.setGraph(this.props[1].showGraph)}>
          {this.props[1].compliant ? 
          <label className={"font-bold flex justify-center text-lg text-gray-700 mt-2 cursor-pointer"}>{this.props[1].rule}</label>
          :
          <label className="font-bold flex justify-center text-lg text-red-600 mt-2 cursor-pointer">{this.props[1].rule}</label> }
        </div>
      )
    } else {
      return (
        <div onClick={() => this.props.setGraph(this.props[1].showGraph)}>
          {this.props[1].compliant ? 
          <label className={"flex justify-center text-lg text-gray-700 mt-2 cursor-pointer hover:font-bold"}>{this.props[1].rule}</label>
          :
          <label className="flex justify-center text-lg text-red-600 mt-2 cursor-pointer hover:font-bold">{this.props[1].rule}</label> }
        </div>
      )
    }
  }
}