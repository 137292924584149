import React, { Component } from 'react';
import { withRouter } from 'react-router';
import bcrypt from 'bcryptjs';
import { RESET_PASS_PAGE_TITLE, LOGIN_PAGE_PATH, RESET_PASS_PATH } from '../../../resources/Vault';
import { ErrorToast } from '../../core/components/ErrorToast';
import { SuccessToast } from '../../core/components/SuccessToast';
import { createPostRequest } from '../utils/axios-utils';

class ResetPassword extends Component {
    constructor() {
        super();
        this.params = new URLSearchParams(window.location.search);
        
        this.state = {
            password1: "",
            password2: "",
            passwordError: false,
            matchError: false,
            blankField: false,
            showSuccToast: false,
            showErrToast: false,
            resetToken: this.params.get("resetToken"),
            resetErrMsg: ""
        }

        this.handleKeypress = this.handleKeypress.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.hideErrToast = this.hideErrToast.bind(this);
        this.hideSuccToast = this.hideSuccToast.bind(this);
    }

    /**
     * Checks for enter key being pressed
     * @param {event} e Key press event
     */
    handleKeypress(e) {
        if (e.charCode === 13) {
        this.checkErrors();
        }
    };

    /**
     * Checks input fields for errors
     */
    checkErrors() {
        let passwordMatch = this.state.password1 === this.state.password2;
        let passwordLength = this.state.password1.length >= 10 && this.state.password1.length <= 32;
        let hasUpper = this.state.password1.toUpperCase() !== this.state.password1;
        let hasLower = this.state.password1.toLowerCase() !== this.state.password1;
        let specialCharacters = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);
        let hasSpecial = specialCharacters.test(this.state.password1);

        // Placeholder state values
        let blankField = false;
        let matchErr = false;
        let passErr = false;

        // Check for blank fields
        if (this.state.password1 == "" || this.state.password2 == "") {
            blankField = true;
        }

        // Check password for compliance
        if (passwordLength && hasSpecial && hasUpper && hasLower) {
            passErr = false;
        } else {
            passErr = true;
        }

        // Check that passwords match
        if (!passwordMatch) {
            matchErr = true;
            
        }
        this.setState({
            ...this.state,
            blankField: blankField,
            passwordError: passErr,
            matchError: matchErr
        });

        this.onSubmit();
    }

    /**
     * Submit user input to backend
     */
    async onSubmit() {
        if (!this.state.blankField && !this.state.matchError && !this.state.passwordError) {
            const body = {
                password: bcrypt.hashSync(this.state.password1)
            };
            const params = {
                resetToken: this.state.resetToken
            };
            
            try {
                const apiResponse = await createPostRequest(RESET_PASS_PATH, {}, body, params);
                this.setState({...this.state, showSuccToast: true});
                this.props.history.push({pathname: LOGIN_PAGE_PATH});
            }
            catch (err) {
                console.log(err);
                let msg = "";
                if (err.response) {
                    // Invalid token
                    if (err.response.status === 400) {
                        msg = "Invalid link";
                    }
                    // Expired token
                    else if (err.response.status === 410) {
                        msg = "This link has expired";
                    }
                }
                // Misc. error
                else {
                    msg = "We encountered an error. Please try again";
                }
                this.setState({
                    ...this.state,
                    showErrToast: true,
                    resetErrMsg: msg
                });
            }
        }
    }

    /**
     * Hides success toast
     */
    hideSuccToast() {
        this.setState({...this.state, showSuccToast: false});
    }

    /**
     * Hides error toast
     */
    hideErrToast() {
        this.setState({...this.state, showErrToast: false});
    }

    /**
     * Component lifecycle method, runs when component mounts
     */
    componentDidMount() {
        document.title = RESET_PASS_PAGE_TITLE;
    }

    render() {
        return (
            <>
                <div className="mx-auto max-w-7xl relative">
                    <div className=" w-full absolute g-gradient-to-r from-white via-white to-gray-100"></div>
                    <div className="flex flex-row justify-center">
                        <div className="w-full bg-green lg:w-6/12 xl:w-5/12">
                            <div className="flex flex-col items-start justify-start w-full h-full p-10 lg:p-16 xl:p-24">
                                <div className="-ml-3">
                                    <p className="mb-4 text-2xl mt-10 font-extrabold leading-snug tracking-tight text-left text-primary md:text-4xl">
                                        Reset Password
                                    </p>
                                </div>
                                <div className="relative">
                                    <label className="font-medium text-gray-900">
                                        Password
                                    </label>
                                    {!this.state.passwordError ? 
                                        <input
                                        onChange={event => this.setState({...this.state,password1: event.target.value})}
                                        type="password"
                                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                                        placeholder="Password"
                                        />
                                    :
                                        <input
                                        onChange={event => this.setState({...this.state,password1: event.target.value, passwordError: false})}
                                        type="password"
                                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                                        placeholder="Password"
                                        />
                                    }
                                </div>
                                <label className="text-red-500">{this.state.passwordError ? "* Password must be 10-32 characters, containing an uppercase, lowercase, and special character" : ""}</label>
                                <div className="relative">
                                    <label className="font-medium text-gray-900">
                                        Confirm Password
                                    </label>
                                    {!this.state.matchError ? 
                                        <input
                                        type="password"
                                        onChange={event => this.setState({...this.state,password2: event.target.value})}
                                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                                        placeholder="Confirm Password"
                                        />
                                    :
                                        <input
                                        type="password"
                                        onChange={event => this.setState({...this.state,password2: event.target.value, matchError: false})}
                                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                                        placeholder="Confirm Password"
                                        />
                                    }
                                </div>
                                <label className="text-red-500">{this.state.matchError ? "* Passwords must match" : ""}</label>
                                <label className="text-red-500">{this.state.blankField ? "* Fields cannot be blank" : ""}</label>

                                <div className="relative">
                                    <button
                                        onClick={ event => this.checkErrors() }
                                        className="inline-block w-full px-5 py-4 text-lg font-medium text-center text-white transition duration-150 bg-primary rounded-lg hover:bg-accent ease"
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="absolute bottom-0 right-0 mr-10 mb-6">
                            {this.state.showErrToast ? <ErrorToast message={this.state.resetErrMsg} visible={this.state.showErrToast} hide={this.hideErrToast}/> : null}
                            {this.state.showSuccToast ? <SuccessToast message={"Password changed successfully"} visible={this.state.showSuccToast} hide={this.hideSuccToast}/> : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(ResetPassword);
