import React, { Component } from 'react';
import Tab from './Tab';

/**
 * Tab bar that displays the tabs
 */
class TabBar extends Component {
  constructor(props) {
    /**
     * TabBar component state
     * @type {Object}
     * @property {Array<tab>} tabs Array of tabs
     */
    super(props)
    this.state = {
      tabs: this.props.tabs,
    }
    this.activateTab = this.activateTab.bind(this)
  }
  /**
   * Sets tabIsActive for a tab to true if the tabID of said tab matches the param tabID
   * @param {number} tabID Id numberof the active tab
   */
  activateTab(tabID) {
    var tabs = this.state.tabs;
    tabs.forEach((tab) => {
      if(tab.tabID == tabID) {
        tab.tabIsActive = true;
      } else {
        tab.tabIsActive = false;
      }
    })
    this.setState({
      ...this.state,
      tabs:tabs
    })
    this.props.updateWindow(tabID);
  }

  render() {
    return (
      <div className="w-full border-2 rounded-xl bg-gray-200">
        {this.state.tabs.map(block => <Tab key={block.tabID} {...block} activateTab = {this.activateTab}/>)}
      </div>
    )
  }   
}

export default TabBar;