import React, { Component } from 'react';
import { LOGIN_PAGE_PATH } from '../../../resources/Vault';
import { withRouter } from "react-router";

// List of events which the event listener is looking for
const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

// Variable that waits for 15 minutes of inactivity before logging the user out
const LOGOUT_WAIT_TIME = 900000;

class AutoLogout extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }

  // Function which logs the user out after 15 minutes of activity
  handleLogoutTimer = () => {
    this.timer = setTimeout(() => {
      this.resetTimer();
      events.forEach((event) => {
        window.removeEventListener(event, this.resetTimer);
      });
      this.logoutAction();
    }, LOGOUT_WAIT_TIME);
  };

  // Reset the timer before logging the user out
  resetTimer = () => {
    if (this.timer) clearTimeout(this.timer);
  };

  // Once the componenet mounts, add the event listener which will check for user activity
  componentDidMount() {
    events.forEach((event) => {
      window.addEventListener(event, () => {
        this.resetTimer();
        this.handleLogoutTimer();
      });
    });
  }

  // Remove event listeners when component unmounts
  componentWillUnmount() {
    events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    });
  }

  // Log out the user, clear local storage
  logoutAction = () => {
    localStorage.clear();
    this.props.history.push({pathname:LOGIN_PAGE_PATH});
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(AutoLogout);