/**
 * @param {Object} ruleSet The rules object that will be formatted to be displayed 
 * @param {Object} evaluation The evaluation object that will be used to determine the compliance of a rule
 * 
 * @returns {Array} ret An array containing the formatted rule to display and a value to determine which 
 *                  graph to display when clicked
 */
export function formatRules(ruleSet, evaluation) {
  let ret = [];
  let metrics = ruleSet.metrics;
  let ruleObject = {};
  let ruleValues = {};
  let compliant = true;

  let metricTitle = "";
  let formattedTitle = "";
  Object.entries(metrics).map(block => {
    ruleValues = block[1];
    metricTitle = block[0];
    Object.entries(ruleValues).map(block2 => {
      if(evaluation && evaluation.hasOwnProperty(metricTitle)) {
        let nestedObject = evaluation[metricTitle];
        if(nestedObject.hasOwnProperty(block2[0])) {
          compliant = false;
        } else {
          compliant = true;
        }
      } else {
        compliant = true;
      }

      if(metricTitle == "gait_velocity") {formattedTitle = "Gait Velocity"}
      else if(metricTitle == "form") {formattedTitle = "Form"}
      ruleObject = {
        rule: formattedTitle + " " + block2[0] + " " + block2[1].compare + " " + block2[1].value,
        showGraph: formattedTitle,
        compliant: compliant
      };
      ret.push(ruleObject);
    })
  })
  return (ret);
}
