/**
 * Triggers a file download of IMU data in the browser
 * @param {string} filename Filename
 * @param {Array<String>} data IMU Data
 */
export function generate_file(filename, data) {
    // Create file locally
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = filename + ".json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}
