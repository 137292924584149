/**
 * Generates URL for getting aggregate dates
 * @param {string} pid Patient ID
 * @returns Constructed URL for /getAggDates
 */
export function aggregateDates(pid) {
    return `/getAggDates/${pid}`;
}

/**
 * Generates URL for getting patient data by date
 * @param {string} pid Patient ID 
 * @param {string} date Date MM-YYYY
 * @returns Constructed URL for /getDataByDate
 */
export function getDataByDate(pid, date){
    return `/getDataByDate/${pid}/${date}`
}

/**
 * Generates URL for getting patient monitoring sessions
 * @param {string} pid Patient ID
 * @returns Constructed URL for /getMonitoringSessions
 */
export function getMonitoringSessions(pid) {
    return `/getMonitoringSessions/${pid}`
}
