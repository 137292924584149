import axios from 'axios';
import { REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY, IS_AUTH_PATH, LOGOUT_PATH } from '../../../resources/Vault'

/**
 * Function which refreshes the users API token
 * @returns {Object} the response of the refresh API
 */
async function refresh_token() {
  const token = localStorage.getItem(REFRESH_TOKEN_KEY);
  
  try {
    const response = await axios.get("/refresh", {
      headers: {
        "x-refresh-token": token
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function createGetRequest(path, headers = {}, body = null) {
  try {
    // Configuire the request
    const response = await axios.get(path, {
      headers: headers,
      params: body,
    });
    return response.data;

  } catch (error) {
    // If 406 error, then call refresh and retry with new token
    if (error.response && error.response.status === 406) {
      try {
        let refreshResponse = await refresh_token();
        let newAuthToken = refreshResponse.data.auth_token;

        // Get the new token and save to local storage
        headers['x-access-token'] = newAuthToken;
        localStorage.setItem(AUTH_TOKEN_KEY, newAuthToken);

        // Retry the original request with the new token
        const retryResponse = await axios.get(path, {
          headers: headers,
          params: body,
        });
        return retryResponse.data;
      
      } catch (refreshError) {
        // Throw an error if the API call fails again
        throw refreshError;
      }
    } else {
      throw error;
    }
  }
}


export async function createPostRequest(path, headers = {}, body = null, params = null) {
  try {
    // Configuire the request
    const response = await axios.post(path, body, {
      headers: headers,
      params: params
    });
    return response.data;

  } catch (error) {
    // If 406 error, then call refresh and retry with new token
    if (error.response && error.response.status === 406) {
      try {
        let refreshResponse = await refresh_token();
        let newAuthToken = refreshResponse.data.auth_token;

        // Get the new token and save to local storage
        headers['x-access-token'] = newAuthToken;
        localStorage.setItem(AUTH_TOKEN_KEY, newAuthToken);

        // Retry the original request with the new token
        const retryResponse = await axios.post(path, body, {
          headers: headers
        });
        return retryResponse.data;

      } catch (refreshError) {
        // Throw an error if the API call fails again
        throw refreshError;
      }
    } else {
      throw error;
    }
  }
}

export async function createDeleteRequest(path, headers = {}, data = null) {
  try {
    // Configure the delete request
    const response = await axios.delete(path, {
      headers: headers,
      data: data
    });
    return response.data;
  } catch (error) {
    if(error.response && error.response.status === 406) {
      try {
        let refreshResponse = await refresh_token();
        let newAuthToken = refreshResponse.data.auth_token;

        // Get the new token and save to local storage
        headers['x-access-token'] = newAuthToken;
        localStorage.setItem(AUTH_TOKEN_KEY, newAuthToken);

        // Retry the original request with the new token
        const retryResponse = await axios.delete(path, {
          headers: headers,
          data: data
        });
        return retryResponse.data;
      
      } catch (refreshError) {
        // Throw an error if the API call fails again
        throw refreshError;
      }
    } else {
      throw error;
    }
  }
}

export async function createPutRequest(path, headers = {}, body = null) {
  try {
    // Configure the put request
    const response = await axios.put(path, body, {
      headers: headers
    });
    return response.data;
  } catch (error) {
    if(error.response && error.response.status === 406) {
      try {
        let refreshResponse = await refresh_token();
        let newAuthToken = refreshResponse.data.auth_token;

        // Get the new token and save to local storage
        headers['x-access-token'] = newAuthToken;
        localStorage.setItem(AUTH_TOKEN_KEY, newAuthToken);

        // Retry the original request with the new token
        const retryResponse = await axios.put(path, body, {
          headers: headers
        });
        return retryResponse.data;
      
      } catch (refreshError) {
        // Throw an error if the API call fails again
        throw refreshError;
      }
    } else {
      throw error;
    }
  }
}