import React, { Component } from "react";
import { Dropdown } from '../../core/components/Dropdown';
import { SessionMetric } from '../components/SessionMetric';
import { SuccessToast } from '../../core/components/SuccessToast';
import { ErrorToast } from '../../core/components/ErrorToast';
import { removeFromArray } from "../../core/utils/remove-from-array";
import { formatInfoBlock, formatSessionBlock } from '../utils/format-add-session-request';
import { formatTemplateLabels, getTemplateData } from '../utils/format-template-data';
import { RightTooltip } from "../../core/components/RightTooltip";
import { LeftTooltip } from "../../core/components/LeftTooltip";
import { MONTH_LABELS, DATE_LABELS, FUTURE_YEAR_LABELS, YEAR_LABELS, METRIC_LABELS, STATISTIC_LABELS, OPERATOR_LABELS, MORE_OPERATOR_LABELS } from  '../../../resources/Config';
import { ADD_MONITORING_SESSION_PATH, ADD_MONITORING_SESSION_TITLE, AUTH_TOKEN_KEY, GET_TEMPLATES_PATH } from "../../../resources/Vault";
import { createPostRequest, createGetRequest } from '../../auth/utils/axios-utils';

export class SessionCreation extends Component{
  
  constructor(props) {
    super(props);
    let d = new Date();
    this.state = {
      month: "Jan",
      day: "01",
      year: d.getFullYear(),
      startDate: "Jan/01/" + d.getFullYear(),
      sessionName: "",
      saveTemplate: false,
      period: "",
      minimumThreshold: "",
      metric: "form",
      operator: "=",
      operator1: "<",
      operator2: "<",
      statistic: "min",
      value: "",
      value1: "",
      value2: "",
      metricsAdded: false,
      metrics: [],
      templateData: [],
      templateLabels: [],
      valueLeftEmpty: false,
      value1LeftEmpty: false,
      value2LeftEmpty: false,
      nameLeftEmpty: false,
      thresholdLeftEmpty: false,
      periodLeftEmpty: false,
      dateError: false,
      showErrorToast: false,
      isRange: false,
      duplicateTemplate: false,
      negateRange: false,
      opacity: false,
      showAPISuccessToast: false,
    };

    this.close = this.close.bind(this);
    this.setMonth = this.setMonth.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setYear = this.setYear.bind(this);
    this.setMetric = this.setMetric.bind(this);
    this.setStatistic = this.setStatistic.bind(this);
    this.setOperator = this.setOperator.bind(this);
    this.addSession = this.addSession.bind(this);
    this.validateMetric = this.validateMetric.bind(this);
    this.removeMetric = this.removeMetric.bind(this);
    this.isDateTooFar = this.isDateTooFar.bind(this);
    this.formatSession = this.formatSession.bind(this);
    this.setTemplate = this.setTemplate.bind(this);
    this.hideErrToast = this.hideErrToast.bind(this);
    this.hideSuccToast = this.hideSuccToast.bind(this);
    this.hideDupToast = this.hideDupToast.bind(this);
  }

  /**
    * Hides success toast
    */
  hideSuccToast() {
    this.setState({...this.state, showAPISuccessToast: false});
  }

  /**
    * Hides error toast
    */
  hideErrToast() {
    this.setState({...this.state, showErrorToast: false});
  }

  /**
    * Hides duplicate template error toast
    */
  hideDupToast() {
    this.setState({...this.state, duplicateTemplate: false});
  }

  close () {
    let d = new Date();
    this.setState({
      month: "Jan",
      day: "01",
      year: d.getFullYear(),
      startDate: "Jan/01/" + d.getFullYear(),
      sessionName: "",
      saveTemplate: false,
      period: "",
      minimumThreshold: "",
      metric: "form",
      operator: "",
      operator1: "",
      operator2: "",
      statistic: "min",
      value: "",
      value1: "",
      value2: "",
      metricsAdded: false,
      metrics: [],
      valueLeftEmpty: false,
      value1LeftEmpty: false,
      value2LeftEmpty: false,
      nameLeftEmpty: false,
      thresholdLeftEmpty: false,
      periodLeftEmpty: false,
      dateError: false,
      showErrorToast: false,
      isRange: false,
      duplicateTemplate: false,
      negateRange: false,
      showAPISuccessToast: false,
    });
    this.props.exit();
  }

  /**
   * Format date given input
   * @param {int} param 0=MM, 1=DD, 2=YYYY
   * @param {string} num Given number to format
   * @returns Formatted date string
   */
  formatDate(param, num) {
    let date;
    switch (param) {
      case 0:
        date = num + "/" + this.state.day + "/" + this.state.year;
        break;
      case 1:
        date = this.state.month + "/" + num + "/" + this.state.year;
        break;
      case 2:
        date = this.state.month + "/" + this.state.day + "/" + num;
    }
    return date;
  }
  setMonth (month) {
    this.setState({...this.state,
                  month: month,
                  dateError: false,
                  startDate: this.formatDate(0, month)});
  }
  setYear (year) {
    this.setState({...this.state,
                  year: year,
                  dateError: false,
                  startDate: this.formatDate(2, year)});
  }
  setDate (date) {
    this.setState({...this.state,
                  day: date,
                  dateError: false,
                  startDate: this.formatDate(1, date)});
  }

  setMetric (metric) {
    this.setState({...this.state,metric: metric});
  }
  setStatistic (statistic) {
    this.setState({...this.state,statistic: statistic});
  }
  handleBox (boxID) {
    this.setState({...this.state,negateRange: !this.state.negateRange});
  } 
  setOperator (operator) {
    if (this.state.isRange){
      if (this.state.operator1 === "") {
        this.setState({...this.state,operator1: operator});
      } else if (this.state.operator2 === "" && this.state.operator1 !== "") {
        this.setState({...this.state,operator2: operator});
      }
      if (this.state.operator1 !== "" && this.state.operator2 !== "") {
        this.setState({...this.state,operator: this.state.operator1 + this.state.operator2});
      }
    } else {
      this.setState({...this.state,operator: operator});
    }
  }

  setTemplate(template) {
    let templateMetrics = getTemplateData(template, this.state.templateData);

    // Set metric state
    this.setState({
      ...this.state, 
      metrics: templateMetrics.metrics,
      period: templateMetrics.period,
      minimumThreshold: templateMetrics.min_threshold
    });
  }
  /** Function which adds a metric to the list of metrics given the value has not been left empty */
  validateMetric () {
    if (this.state.isRange) {
      if (this.state.value1 !== "" && this.state.value2 !== "") {
        let val = this.state.value1 + "," +this.state.value2;
        let key = this.state.metric + '-' + this.state.statistic + '-' + this.state.operator1 + '-' + val;
        let op = this.state.operator1 + this.state.operator2;
        if (this.state.negateRange) {
          op = "!" + op;
        }
        let metric = {}
        metric = {
            id: key,
            metric: this.state.metric,
            statistic: this.state.statistic,
            operator1: this.state.operator1,
            operator2: this.state.operator2,
            value1: this.state.value1,
            value2: this.state.value2,
            operator: op,
            value: val,
            isRange: true, 
        }
        this.setState({...this.state,
          metrics: [...this.state.metrics, metric],
          value: "",
          value1: "",
          value2: "",
          metricsAdded: true,
          valueLeftEmpty: false,
          value1LeftEmpty: false,
          value2LeftEmpty: false,
        });
        document.getElementById("value1").value = "";
        document.getElementById("value2").value = "";
        document.getElementById("checkbox").checked = false;
      } else {
        if (this.state.value1 === "" && this.state.value2 === "") {
          this.setState({
            ...this.state, 
            value1LeftEmpty: true,
            value2LeftEmpty: true,
          });
        } else if (this.state.value1 == "") {
          this.setState({...this.state,value1LeftEmpty: true});
        } else if (this.state.value2 == "") {
          this.setState({...this.state,value2LeftEmpty: true});
        }
      }
    } else {
      if(this.state.value != "") {
        let key = this.state.metric + '-' + this.state.statistic + '-' + this.state.operator + '-' + this.state.value;
        let metric = {}
        metric = {
            id: key,
            metric: this.state.metric,
            statistic: this.state.statistic,
            operator: this.state.operator,
            value: this.state.value,
            isRange: false,
        }
        this.setState({...this.state,
          metrics: [...this.state.metrics, metric],
          value: "",
          metricsAdded: true,
          valueLeftEmpty: false,});
        document.getElementById("value").value = "";
      } else {
        this.setState({...this.state,valueLeftEmpty: true});
      }
    }
  }

  /** Function which removes a given metric from the array of metrics that have been added by the user
   * Executed when the user clicks on the X button inside a metric, and updates metrics in state
   * @param {String} value the key to the metric that is being removed
   */
  removeMetric(value) {
    let removedArray = removeFromArray(this.state.metrics, value);
    this.setState({...this.state,metrics: removedArray});
  }

  /** Function which determines if the user selected date is more than 30 days in the furutre
   *  or in the past
   * @returns {boolean} true or false depending on whether or not we find any errors
   */
  isDateTooFar() {
    let currentDate = new Date()
    let selectedDate = new Date(this.state.startDate)
    let diff = (selectedDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);
    if(diff >= 30 || diff < 0) {
      return true
    } else {
      return false
    }
  }

  /**
   * Adds session ID to session object
   * @param {Object} data 
   * @returns Formatted Session Object
   */
  formatSession(data) {
    let key = Object.keys(data)[0];
    let session = data[key];
    session['id'] = key;
    return session;
  }

  /** Function which checks for any errors, and handles the backend request to add a session
   * If the request completes successfully, then we reset our state, and close the modal
   * If there are any errors, then we display our error toast message
   */
  async addSession() {
    if(this.state.sessionName == "" || this.state.minimumThreshold == "" || this.state.period == "" || this.isDateTooFar()) {
      this.setState({...this.state,
        nameLeftEmpty: this.state.sessionName == "", 
        thresholdLeftEmpty: this.state.minimumThreshold == "",
        periodLeftEmpty: this.state.period == "",
        dateError: this.isDateTooFar()
      })
    } else {
      this.setState({...this.state, opacity: true});
      const headers = { "x-access-token": localStorage.getItem("auth_token") };
      const body = {
        title: ADD_MONITORING_SESSION_TITLE,
        info: formatInfoBlock(this.state),
        session: formatSessionBlock(this.state)
      }

      try {
        const addSessionResponse = await createPostRequest(ADD_MONITORING_SESSION_PATH, headers, body);

        this.setState({...this.state, opacity: false});
        let d = new Date();
          // If we are saving a template, then we need to add that template to state
        if(this.state.saveTemplate) {
          // Create template object to add
          let template = [formatSessionBlock(this.state).rules];
          let templateLabel = {value: this.state.sessionName, lebel: this.state.sessionName}

          // Add the template to the existing templates
          this.setState({...this.state,
            templateData: [...this.state.templateData, template],
            templateLabels: [...this.state.templateLabels, templateLabel]
          })
        }

        this.setState({...this.state,
          month: "Jan",
          day: "01",
          year: d.getFullYear(),
          startDate: "Jan/01/" + d.getFullYear(),
          sessionName: "",
          saveTemplate: false,
          period: "",
          minimumThreshold: "",
          metric: "form",
          operator: "",
          operator1: "",
          operator2: "",
          statistic: "min",
          value: "",
          metricsAdded: false,
          metrics: [],
          valueLeftEmpty: false,
          value1LeftEmpty: false,
          value2LeftEmpty: false,
          nameLeftEmpty: false,
          thresholdLeftEmpty: false,
          periodLeftEmpty: false,
          dateError: false,
          showErrorToast: false,
          showAPISuccessToast: true,
          negateRange: false,
          opacity: false,
        });
        this.props.submit(this.formatSession(addSessionResponse));
      } catch(error) {
        this.setState({...this.state, opacity: false});
        if(error.response && error.response.status === 422) {
          // Template already exists with name that is being sent
          this.setState({...this.state, duplicateTemplate: true})
        } else {
          this.setState({...this.state, showErrorToast: true, showAPISuccessToast: false})
        }
      }
    }
  }

  async componentDidMount() {
    // Configure the get templates request
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    
    try {
      const getTemplateDataResponse = await createGetRequest(GET_TEMPLATES_PATH, headers);
      this.setState({
        ...this.state, 
        templateLabels: formatTemplateLabels(getTemplateDataResponse),
        templateData: getTemplateDataResponse
      });
    } catch(error) {
      console.log(error);
    }
  }

  render(){
    if (!this.props.visible) return null;
    return (
      <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
        <div className="bg-white h-5/6 w-1/2 overflow-auto overscroll-none px-6 pb-6 rounded">
          {/* -----------------------------HEADER BAR--------------------------------------- */}
          <div className="sticky top-0 z-40 flex justify-between bg-white border-b-2 h-12 p-2 -mx-6">
            <div className="w-3/5 flex justify-end">
              <h1 className="font-semibold text-center text-xl text-gray-700">
                Session Creation
              </h1>
            </div>
            <div className="w-1/4 flex justify-end">
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-lg hover:bg-red-400 "
                onClick={() => this.close()}>
                  X
              </button>
            </div>
          </div>
          
          <div className="flex">
            {/* -----------------------------LEFT SIDE STUFF---------------------------------------- */}
            <div className="flex-auto">
              <div className="w-5/6">
                <div className="relative mt-2">
                  <label className="font-medium text-gray-900">Session Name</label>
                  {!this.state.nameLeftEmpty && !this.state.duplicateTemplate ? 
                    <input
                      onChange={event => this.setState({...this.state,sessionName:event.target.value})}
                      onKeyPress={this.handleKeypress}
                      className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                      placeholder="Name"
                      id="sessionName"
                    />
                  : 
                    <input
                      onChange={event => this.setState({...this.state,sessionName:event.target.value, nameLeftEmpty: false, duplicateTemplate: false})}
                      onKeyPress={this.handleKeypress}
                      className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                      placeholder="Name"
                      id="sessionName"
                    />
                  }
                </div>

                <div className="relative mt-2">
                  <div className="inline-flex">
                    <label className="font-medium text-gray-900 mr-2">Minimum Threshold</label>
                    <RightTooltip message={"The number of data sets to be collected during a monitoring session"}/>
                  </div>
                  {!this.state.thresholdLeftEmpty ? 
                    <input
                      value={this.state.minimumThreshold}
                      onChange={event => this.setState({...this.state,minimumThreshold:event.target.value})}
                      onKeyPress={this.handleKeypress}
                      className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                      placeholder="Threshold"
                      id="threshold"
                    />
                  :
                    <input
                      value={this.state.minimumThreshold}
                      onChange={event => this.setState({...this.state,minimumThreshold:event.target.value, thresholdLeftEmpty: false})}
                      onKeyPress={this.handleKeypress}
                      className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                      placeholder="Threshold"
                      id="threshold"
                    />
                  }
                </div>     

                {/* We need this check here to prevent rendering the dropdown with no templates */}
                { this.state.templateLabels.length != 0 ?
                  <div>
                    <div className="my-2">
                      <label className="font-medium text-gray-900">
                        Templates
                      </label>
                      <div className="relative">
                        <Dropdown 
                          options={this.state.templateLabels}
                          setValue={this.setTemplate}
                          buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full p-3 hover:bg-gray-300"
                          menuStyle="absolute bg-white z-40 max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"
                          id="templates"/>
                      </div>
                    </div>
                  </div>
                :
                null }

                <div className="relative mt-2">
                  <div className="inline-flex">
                    <label className="font-medium text-gray-900 mr-2">
                      Start Date
                    </label>
                    <RightTooltip message={"Start Date must be no longer than 30 days away from the current date"}/>
                  </div>
                  <div className="flex">
                    <div className="relative w-1/3 mr-1">
                      <Dropdown 
                        options={MONTH_LABELS}
                        setValue={this.setMonth}
                        buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full p-3 hover:bg-gray-300"
                        menuStyle="absolute z-40 bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"
                        id="month"
                        />
                    </div>
                    <div className="relative w-1/3 mr-1">
                      <Dropdown 
                        options={DATE_LABELS} 
                        setValue={this.setDate}
                        buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full p-3 hover:bg-gray-300"
                        menuStyle="absolute z-40 bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"
                        id="day"/>
                    </div>
                    <div className="relative w-1/3 mr-1">
                      <Dropdown 
                        options={FUTURE_YEAR_LABELS}
                        setValue={this.setYear}
                        buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full p-3 hover:bg-gray-300"
                        menuStyle="absolute z-40 bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"
                        id="year"/>
                    </div>
                  </div>
                  <div>
                    {this.state.dateError ? 
                      <label className="font-medium text-red-600 ">Date is out of bounds, see tooltip for help</label>
                    : null }
                  </div>
                </div>

                <div className="relative mt-2">
                  <div className="inline-flex">
                    <label className="font-medium text-gray-900 mr-2">Period</label>
                    <RightTooltip message={"The duration of the monitoring session in days"}/>
                  </div>
                  {!this.state.periodLeftEmpty ? 
                    <input
                      value={this.state.period}
                      onChange={event => this.setState({...this.state,period:event.target.value})}
                      onKeyPress={this.handleKeypress}
                      className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                      placeholder="Days"
                      id="period"
                    />
                  :
                    <input
                      value={this.state.period}
                      onChange={event => this.setState({...this.state,period:event.target.value, periodLeftEmpty: false})}
                      onKeyPress={this.handleKeypress}
                      className="block w-full px-4 py-4 mt-1 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                      placeholder="Days"
                      id="period"
                    />
                  }
                </div>

              {/* -----------------------------METRICS CONTAINER---------------------------------------- */}
              </div>

              <div className="w-full border-2 px-2 mt-6 h-3/5 rounded-xl overflow-auto">
                <div className="sticky top-0 z-40 bg-white border-b-2 h-12 p-2 -mx-2">
                  <label className="flex justify-center font-semibold text-center text-xl text-gray-700">Metrics</label>
                </div>
                <div className="w-full">
                  {!this.state.metrics ? null : this.state.metrics.map(block => <SessionMetric {...block} removeMetric={this.removeMetric}/>)}
                </div>
              </div>
            </div>

            {/* -----------------------------RIGHT SIDE STUFF---------------------------------------- */}
            <div className="flex-auto ml-20 max-w-xs">
              <div className="mb-3 mt-3 inline-flex">
                <label className="font-semibold text-center flex justify-center text-xl text-gray-700 mr-2">Create Monitor Metric</label>
                <LeftTooltip message={"Add a metric to your monitoring session"}/>
              </div>

              <div className="mb-2">
                <div className="inline-flex">
                  <label className="font-medium text-gray-900 mr-2">
                    Metric
                  </label>
                  <RightTooltip message={"Select the metric that you would like to monitor"}/>
                </div>
                <div className="relative">
                  <Dropdown 
                    options={METRIC_LABELS}
                    setValue={this.setMetric}
                    buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full p-3 hover:bg-gray-300"
                    menuStyle="absolute z-40 bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"
                    id="metric"
                    />
                </div>
              </div>

              <div className="mb-2">
                <label className="font-medium text-gray-900">
                  Statistic
                </label>
                <div className="relative">
                  <Dropdown 
                    options={STATISTIC_LABELS}
                    setValue={this.setStatistic}
                    buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full p-3 hover:bg-gray-300"
                    menuStyle="absolute z-40 bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"
                    id="statistic"
                    />
                </div>
              </div>

              <div className="mb-2">
                <label className="font-medium text-gray-900">
                  Operator
                </label>
                <div className = "border-2 border-gray-300 rounded-lg w-full p-3 space-y-2">
                  <div className="flex justify-center space-x-10">
                    <button className={"border-2 rounded-lg p-1 " + (this.state.isRange ? "border-gray-300" : "border-primary")}
                    onClick={() => this.setState({...this.state, isRange: false})}>
                      Single
                    </button>
                    <button className={"border-2 rounded-lg p-1 " + (this.state.isRange ? "border-primary" : "border-gray-300")}
                    onClick={() => this.setState({...this.state, isRange: true})}>
                      Range
                    </button>
                  </div>
                  <div className="flex justify-center">
                    <hr className="w-3/4"/>
                  </div>
                  {this.state.isRange ? 
                  /* -----------------------------Range---------------------------------------- */
                    <div>
                      <div className="flex justify-center items-center space-x-2 relative">
                        {!this.state.value1LeftEmpty ? 
                          <input
                            id="value1"
                            onChange={event => this.setState({...this.state,value1:event.target.value})}
                            onKeyPress={this.handleKeypress}
                            className="block w-1/5 px-2 py-1 text-l placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                            placeholder="Value"
                          />
                        :
                          <input
                            id="value1"
                            onChange={event => this.setState({...this.state,value1:event.target.value})}
                            onKeyPress={this.handleKeypress}
                            className="block w-1/5 px-2 py-1 text-l placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                            placeholder="Value"
                          />
                        }
                        <div className="relative">
                          <Dropdown 
                            options={MORE_OPERATOR_LABELS}
                            setValue={this.setOperator}
                            buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full px-2 py-1 hover:bg-gray-300"
                            menuStyle="absolute bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"
                            id="opdropdown"  
                          />
                        </div>
                        <div className="flex">
                          {this.state.statistic + " " + this.state.metric}
                        </div>
                        <div className="relative">
                          <Dropdown 
                            options={MORE_OPERATOR_LABELS}
                            setValue={this.setOperator}
                            buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full px-2 py-1 hover:bg-gray-300"
                            menuStyle="absolute bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"
                            id="opdropdown2"
                            />
                        </div>
                        {!this.state.value2LeftEmpty ? 
                          <input
                            id="value2"
                            onChange={event => this.setState({...this.state,value2:event.target.value})}
                            onKeyPress={this.handleKeypress}
                            className="block w-1/5 px-2 py-1 text-l placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                            placeholder="Value"
                          />
                        :
                          <input
                            id="value2"
                            onChange={event => this.setState({...this.state,value2:event.target.value})}
                            onKeyPress={this.handleKeypress}
                            className="block w-1/5 px-2 py-1 text-l placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                            placeholder="Value"
                          />
                        }
                      </div>
                      <div className="mt-2 inline-flex">
                        <label>
                          <input
                          className= "mx-2"
                          type = "checkbox"
                          onChange={() => this.handleBox(1)}
                          id="checkbox"
                          />
                          Exclude these values
                        </label>
                        <LeftTooltip message={"Only values outside of this range will be tracked"}/>
                      </div>
                    </div>
                  :
                  /* -----------------------------Single---------------------------------------- */
                    <div className="flex justify-center">
                      <div className="flex items-center mr-2">
                        {this.state.statistic + " " + this.state.metric}
                      </div>
                      <div className="relative">
                        <Dropdown 
                          id="opdropdown"
                          options={OPERATOR_LABELS}
                          setValue={this.setOperator}
                          buttonStyle="flex justify-center border-2 border-gray-300 rounded-lg w-full px-2 py-1 hover:bg-gray-300"
                          menuStyle="absolute bg-white max-h-24 mt-1 overflow-auto border-l-2 border-r-2 border-b-2 border-gray-100 rounded-lg w-full p-1 shadow-xl"/>
                      </div>
                      {!this.state.valueLeftEmpty ? 
                        <input
                          id="value"
                          onChange={event => this.setState({...this.state,value:event.target.value})}
                          onKeyPress={this.handleKeypress}
                          className="ml-2 block w-1/4 px-2 py-1 text-l placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          placeholder="Value"
                        />
                      :
                        <input
                          id="value"
                          onChange={event => this.setState({...this.state,value:event.target.value})}
                          onKeyPress={this.handleKeypress}
                          className="block w-1/4 px-2 py-1 text-l placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          placeholder="Value"
                        />
                      }
                    </div>
                  }
                </div>
              </div>
              <button 
                className="mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                onClick={() => this.validateMetric()}>
                  Add Metric
              </button>

              {!this.state.metrics.length == 0 ? 
                <div className="w-full bg-gray-200 border-2 p-2 border-red mt-6 rounded-xl overflow-auto">
                  <button 
                    className="mt-2 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                    onClick={() => this.addSession()}>
                      Add Session
                  </button>
                  <div className="mt-3">
                    <input 
                      id="checkbox"
                      className="mx-2"
                      type="checkbox"
                      onChange={event => this.setState({...this.state, saveTemplate: true})} />
                    <div className="inline-flex">
                    <label className="font-medium text-gray-900 mr-2">Save This Session as a Template</label>
                    </div>
                  </div>
                </div>
              : null }
            </div>
            {/* ---------------------------------------------------------------------------- */}
          </div>

          <div className="absolute bottom-0 right-0 mr-10 mb-6">
            {this.state.showErrorToast ? 
              <ErrorToast message={"Internal Error when Adding Session"} visible={this.state.showErrorToast} hide={this.hideErrToast}/>
            : null }
            {this.state.showAPISuccessToast ? 
              <SuccessToast message={"Session Added"} visible={this.state.showAPISuccessToast} hide={this.hideSuccToast}/>
            : null }
            {this.state.duplicateTemplate ?
              <ErrorToast message={"Session Already Exists With That Name"} visible={this.state.duplicateTemplate} hide={this.hideDupToast}/>
            : null}
          </div>
        </div>
      </div>
    )
  }
}
