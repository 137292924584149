/**
 * @param {Array} sessions The array of sessions that is displayed in the aggregate window
 * @param {String} sessionID The session's ID to be removed
 * 
 * @returns {Array} Array with the updated list of sessions to be displayed in the aggregate window
 */

export function removeSession(sessions, sessionID) {
  let removeIndex;
  for(let i = 0; i < sessions.length; ++i) {
    if (sessions[i].id == sessionID) {
      removeIndex = i;
    }
  }
  sessions.splice(removeIndex, 1);
  return(sessions);
}