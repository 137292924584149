import React, { Component } from 'react';
import { Dropdown } from './Dropdown';

/**
 * Component for selecting the month to display in patient data
 */
class DateSelection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="w-full rounded-xl">
                 <div className="flex justify-center items-stretch space-x-3">
                    <label className="flex text-primary items-center text-xl font-bold">
                        Month to Display: 
                    </label> 
                    <div className= "relative">                  
                        {this.props.dates.length > 0 ? <Dropdown options={this.props.dates}
                                                    setValue={this.props.setDate}
                                                    buttonStyle="flex justify-center border-4 border-primary rounded-lg w-64 p-3"
                                                    menuStyle="absolute z-40 bg-white max-h-24 overflow-auto border-l-2 border-r-2 border-b-2 border-primary rounded-lg w-64 p-1" /> 
                        : "Loading..."}
                    </div>
                </div>
            </div>
        )
    }
}

export default DateSelection;
